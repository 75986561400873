import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

import { RealtimeProviders } from './layout/providers';
import { SimpleSidebarLayout } from 'src/components/Layout';
import { RealtimePage } from './layout';
import { useConfigSelectors } from 'src/app/reducers';

import { useHasRoutePermissions } from 'src/@operations/hooks/useHasRoutePermissions'; ''
import { REALTIMEDISPLAY } from 'src/app/featureToggles';
import { REALTIME } from 'src/constants';

export const RealtimeScreen = () => {
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_REALTIMEDISPLAY = configSelectors.isFeatureAvailable(REALTIMEDISPLAY);

  if (!FEATURE_FLAG_REALTIMEDISPLAY) {
    return <p>Feature not enabled</p>;
  }

  if (!useHasRoutePermissions(REALTIME)) {
    return <p>Permission denied</p>;
  }

  return (
    <RealtimeProviders>
      <SimpleSidebarLayout>
        <RealtimePage />
      </SimpleSidebarLayout>
    </RealtimeProviders>
  );
};

export const Realtime = withRouteProps(RealtimeScreen);
