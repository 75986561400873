import { FlightPosition } from 'src/@realtime/contexts';
import { isoToTimestamp } from 'src/@realtime/utils/timestamps';
import { OperationItem, OperationPoint } from 'src/@realtime/queries';
import { calculateBearing } from 'src/@realtime/utils';

const BUFFER_OVER = 10 * 60 * 1000; // 10 minutes in milliseconds

export const buildTrackData = (
  operation: OperationItem,
  minTimestamp: number,
  maxTimestamp: number
): FlightPosition[] | null => {
  if (!operation.points.length) {
    return null;
  }

  const operationStartTimestamp = isoToTimestamp(operation.startTime);

  // Apply buffer to timestamps, gives better looking tails etc
  const bufferedMinTimestamp = minTimestamp - BUFFER_OVER;
  const bufferedMaxTimestamp = maxTimestamp + BUFFER_OVER;

  const calculateHeading = (previous: OperationPoint, current: OperationPoint) =>
    calculateBearing([previous.lon, previous.lat], [current.lon, current.lat]);

  return operation.points
    .map((point, index, points) => ({
      time: operationStartTimestamp + point.t * 1000,
      latitude: point.lat,
      longitude: point.lon,
      altitude: point.alt,
      heading: index === 0 ? 0 : calculateHeading(points[index - 1], point),
    }))
    .filter(point => point.time >= bufferedMinTimestamp || point.time <= bufferedMaxTimestamp);
};
