import { useMemo, useRef } from 'react';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';
import { point, featureCollection, Feature, Point } from '@turf/turf';
import { SpriteKeys } from 'src/@realtime/utils/sprites';
import { PLAYBACK_TICK_INTERVAL_MS, SHADOW_ICON_OFFSETS } from 'src/@realtime/constants';
import { findPosition } from 'src/@realtime/utils/flight';

interface FlightProperties {
  trackId: string;
  iconKey: SpriteKeys;
  shadowOffset: [number, number];
  heading: number;
  operationType: string;
  operationIconKey: string;
  altitude: number;
}

export const useBuildFlightData = () => {
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();
  const {
    state: { positions, flightInfo, lastFlightPruneTime },
  } = useFlightDataContext();

  // Cache heading calculations
  const headingCache = useRef(new Map<string, number>());

  // Toggle Helicopter blade rotation
  const lastBladeToggleTimeRef = useRef(Date.now());
  const heliBladeToggleRef = useRef(false);

  // Cache feature collection to prevent unnecessary updates
  const previousFeatures = useRef<Feature<Point, FlightProperties>[]>([]);
  const previousTimestamp = useRef<number>(0);
  const previousPositionsRef = useRef(positions);

  // Memoize shadow offset calculations
  const shadowOffsetCache = useMemo(() => {
    const cache = new Map<number, [number, number]>();
    for (let i = 0; i < 360; i += 15) {
      cache.set(i, SHADOW_ICON_OFFSETS[i] || [0, 0]);
    }
    return cache;
  }, []);

  return useMemo(() => {
    // Skip update if nothing has changed
    if (
      currentTimestamp === previousTimestamp.current &&
      positions === previousPositionsRef.current &&
      previousFeatures.current.length > 0
    ) {
      return featureCollection(previousFeatures.current);
    }

    const features: Feature<Point, FlightProperties>[] = [];
    const tracksToProcess = Object.entries(positions);

    // Process in smaller chunks to maintain responsiveness
    const chunkSize = 50;
    let currentIndex = 0;

    while (currentIndex < tracksToProcess.length) {
      const endIndex = Math.min(currentIndex + chunkSize, tracksToProcess.length);
      const chunk = tracksToProcess.slice(currentIndex, endIndex);

      for (const [trackId, flightPositions] of chunk) {
        if (flightPositions.length < 2) {
          continue;
        }

        const [pos] = findPosition(flightPositions, currentTimestamp);
        if (!pos) {
          continue;
        }

        const { longitude, latitude, altitude, heading } = pos;
        const flightData = flightInfo[trackId];
        if (!flightData) {
          continue;
        }

        const { operationType, aircraftCategory } = flightData;

        // Use cached heading or calculate new one
        let roundedHeading = headingCache.current.get(trackId);
        if (roundedHeading === undefined || Math.abs(heading - roundedHeading) > 15) {
          roundedHeading = Math.round(heading / 15) * 15;
          headingCache.current.set(trackId, roundedHeading);
        }

        // Animate Helicopter blades efficiently and stably each second.
        let aircraftCategoryIcon: SpriteKeys = null;
        if (aircraftCategory.toLowerCase() === 'helicopter') {
          const now = Date.now();

          if (now - lastBladeToggleTimeRef.current >= PLAYBACK_TICK_INTERVAL_MS) {
            heliBladeToggleRef.current = !heliBladeToggleRef.current;
            lastBladeToggleTimeRef.current = now;
          }

          aircraftCategoryIcon = heliBladeToggleRef.current
            ? SpriteKeys.helicopter1
            : SpriteKeys.helicopter2;
        } else {
          aircraftCategoryIcon = aircraftCategory.toLowerCase() as SpriteKeys;
        }

        features.push(
          point<FlightProperties>(
            [longitude, latitude, altitude],
            {
              trackId,
              iconKey: aircraftCategoryIcon,
              shadowOffset: shadowOffsetCache.get(roundedHeading) || [0, 0],
              heading,
              operationType,
              operationIconKey: operationType.toLowerCase(),
              altitude,
            },
            { id: trackId }
          )
        );
      }

      currentIndex = endIndex;
    }

    // Clean up heading cache
    const activeTrackIds = new Set(tracksToProcess.map(([id]) => id));
    for (const trackId of Array.from(headingCache.current.keys())) {
      if (!activeTrackIds.has(trackId)) {
        headingCache.current.delete(trackId);
      }
    }

    previousFeatures.current = features;
    previousTimestamp.current = currentTimestamp;
    previousPositionsRef.current = positions;

    return featureCollection(features);
  }, [
    currentTimestamp,
    positions,
    flightInfo,
    lastFlightPruneTime,
    findPosition,
    shadowOffsetCache,
  ]);
};
