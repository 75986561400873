import { NmtState, NmtAction, NmtActionType, NoiseSample } from './types';

export const nmtReducer = (state: NmtState, action: NmtAction): NmtState => {
  switch (action.type) {
    case NmtActionType.ADD_MONITORS:
      return {
        ...state,
        hasLoaded: true,
        monitors: [...state.monitors, ...action.payload],
      };
    case NmtActionType.RESET_SAMPLE_DATA:
      return {
        ...state,
        samples: {},
      };
    case NmtActionType.REMOVE_MONITORS:
      return {
        ...state,
        monitors: state.monitors.filter(monitor => !action.payload.includes(monitor)),
      };
    case NmtActionType.ADD_NOISE_SAMPLE: {
      const sample = action.payload;
      if (!sample.deviceLocationId) {
        console.warn('No deviceLocationId found in noise samples. Skipping update.');
        return state;
      }

      const updatedSamples = {
        ...state.samples,
        [sample.deviceLocationId]: [
          ...(state.samples[sample.deviceLocationId] || []),
          {
            time: sample.time,
            value: sample.value,
          },
        ],
      };

      return {
        ...state,
        samples: updatedSamples,
      };
    }
    case NmtActionType.ADD_NOISE_SAMPLES: {
      const samples = action.payload;
      const updatedSamples = samples.reduce((acc, sample) => {
        if (!sample.deviceLocationId) {
          console.warn('No deviceLocationId found in noise samples. Skipping update.');
          return acc;
        }

        return {
          ...acc,
          [sample.deviceLocationId]: [
            ...(acc[sample.deviceLocationId] || []),
            {
              time: sample.time,
              value: sample.value,
            },
          ],
        };
      }, state.samples);

      return {
        ...state,
        samples: updatedSamples,
      };
    }
    case NmtActionType.ADD_HISTORICAL_SAMPLES: {
      return {
        ...state,
        samples: action.payload,
      };
    }
    case NmtActionType.PRUNE_OLD_NOISE_DATA: {
      const pruneThresholdTime = action.payload;
      const prunedSamples = Object.fromEntries(
        Object.entries(state.samples)
          .map(([deviceLocationId, sampleList]) => {
            // Track retained samples and last removed timestamp
            const filteredSamples: NoiseSample[] = [];
            let lastRemovedTime: number | null = null;

            for (const sample of sampleList) {
              const sampleTime = new Date(sample.time).getTime();

              if (sampleTime >= pruneThresholdTime) {
                filteredSamples.push(sample);
              } else {
                lastRemovedTime =
                  lastRemovedTime !== null ? Math.max(lastRemovedTime, sampleTime) : sampleTime;
              }
            }

            return filteredSamples.length > 0 ? [deviceLocationId, filteredSamples] : null;
          })
          .filter(Boolean)
      );

      return {
        ...state,
        samples: prunedSamples,
        lastNoisePruneTime: Date.now(),
      };
    }
    default:
      return state;
  }
};
