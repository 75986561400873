import styled from 'styled-components/macro';

export const TimeAndDateWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 2px;

  @media (max-width: 540px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    margin-top: -2px;
  }
`;

export const StyledTimeDisplay = styled.div`
  display: flex;
  gap: 5px;

  @media (max-width: 540px) {
    font-size: 10px;
  }
`;

export const StyledDateDisplay = styled.div`
  display: flex;
  gap: 5px;

  @media (max-width: 540px) {
    font-size: 10px;
    margin-top: -18px;
  }
`;
