import { FlightDataAction, FlightDataActionType, FlightDataState } from './types';

import { initialFlightDataState } from './context';

export const flightReducer = (
  state: FlightDataState,
  action: FlightDataAction
): FlightDataState => {
  switch (action.type) {
    case FlightDataActionType.ADD_FLIGHT: {
      const { trackId } = action.payload;

      // Only add static info if this trackId is new
      if (!state.flightInfo[trackId]) {
        return {
          ...state,
          flightInfo: {
            ...state.flightInfo,
            [trackId]: {
              ...action.payload,
            },
          },
        };
      }
      return state;
    }
    case FlightDataActionType.RESTART: {
      return initialFlightDataState;
    }
    case FlightDataActionType.ADD_POSITIONS: {
      const { trackId, positions } = action.payload;
      const positionsPayload = Array.isArray(positions) ? positions : [positions];

      const updatedPositions = { ...state.positions };

      // Add each position to the respective trackId
      for (const positionData of positionsPayload) {
        const { time, latitude, longitude, altitude, heading } = positionData;

        // Initialize the positions array if it doesn't exist
        if (!updatedPositions[trackId]) {
          updatedPositions[trackId] = [];
        }

        // Check if a point with the same timestamp already exists
        const existingIndex = updatedPositions[trackId].findIndex(pos => pos.time === time);

        // Append the new position data if it doesn't exist already
        if (existingIndex === -1) {
          updatedPositions[trackId].push({
            time: new Date(time).getTime(),
            latitude,
            longitude,
            altitude,
            heading,
          });
        }
      }

      return {
        ...state,
        positions: updatedPositions,
      };
    }
    case FlightDataActionType.PRUNE_OLD_FLIGHT_DATA: {
      const pruneThresholdTime = action.payload;

      // First, remove old positions inside each track
      const filteredPositions = Object.fromEntries(
        Object.entries(state.positions).map(([trackId, trackPositions]) => [
          trackId,
          trackPositions.filter(position => position.time >= pruneThresholdTime), // ✅ Remove old positions
        ])
      );

      // Then, remove tracks that have no remaining positions
      const prunedPositions = Object.fromEntries(
        Object.entries(filteredPositions).filter(([_, trackPositions]) => trackPositions.length > 0) // ✅ Remove empty tracks
      );

      // Ensure flightInfo is also removed for pruned tracks
      const prunedFlightInfo = Object.fromEntries(
        Object.entries(state.flightInfo).filter(([trackId]) => prunedPositions[trackId]) // Keep only valid tracks
      );

      return {
        ...state,
        positions: { ...prunedPositions },
        flightInfo: { ...prunedFlightInfo },
        lastFlightPruneTime: Date.now(),
        selectedTracks: [...state.selectedTracks],
      };
    }
    case FlightDataActionType.SELECT_TRACK: {
      const trackId = action.payload;
      const { selectedTracks } = state;

      // Support for multiple tracks
      // const updatedSelectedTracks = selectedTracks.includes(trackId)
      //   ? selectedTracks.filter(id => id !== trackId)
      //   : [...selectedTracks, trackId];

      // Currently we only allow one track selected at a time
      const updatedSelectedTracks = selectedTracks.includes(trackId)
        ? [] // Deselect if already selected
        : [trackId]; // Replace with the new selection

      return {
        ...state,
        selectedTracks: updatedSelectedTracks,
      };
    }
    default:
      return state;
  }
};
