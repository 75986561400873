import { useCallback } from 'react';
import { useResetRealtimeData } from 'src/@realtime/components/historicalConnections/hooks';
import { LOADING_BATCH_TIME } from 'src/@realtime/constants';
import {
  PlaybackActionType,
  PlaybackMode,
  PlaybackStatus,
  usePlaybackContext,
} from 'src/@realtime/contexts';
import { useUrlParam } from '../url';

export const useStartPlayback = () => {
  const resetData = useResetRealtimeData();
  const { removeUrlParam } = useUrlParam();
  const {
    state: { minTimestamp, maxPlayableTimestamp, playbackStatus, playbackMode },
    dispatch,
  } = usePlaybackContext();

  return useCallback(
    (goLive: boolean) => {
      if (goLive) {
        if (playbackMode === PlaybackMode.Historical) {
          removeUrlParam('date');
          resetData({});
          dispatch({
            type: PlaybackActionType.SET_PLAYBACK_MODE,
            payload: PlaybackMode.Realtime,
          });
        }
        dispatch({
          type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
          payload: maxPlayableTimestamp - LOADING_BATCH_TIME,
        });
        dispatch({
          type: PlaybackActionType.SET_IS_LIVE,
          payload: true,
        });
      } else {
        dispatch({
          type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
          payload: minTimestamp - LOADING_BATCH_TIME,
        });
      }

      if (playbackStatus !== PlaybackStatus.Playing) {
        dispatch({
          type: PlaybackActionType.SET_PLAYBACK_STATUS,
          payload: PlaybackStatus.Playing,
        });
      }
    },
    [dispatch, minTimestamp, maxPlayableTimestamp, playbackStatus, playbackMode]
  );
};
