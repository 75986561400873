import {
  NmtActionType,
  useNmtContext,
  FlightDataActionType,
  useFlightDataContext,
  PlaybackActionType,
  usePlaybackContext,
} from 'src/@realtime/contexts';
import {
  RESTART_WAIT_TIME_AFTER_USER_RETURNS,
  BACK_TO_THE_FUTURE_AFTER_USER_RETURNS,
  PLAYBACK_BUFFER_SIZE,
} from 'src/@realtime/constants';

const useRestartPlayback = (
  startFlightConnection: () => Promise<void>,
  startNoiseConnection: () => Promise<void>
): (() => void) => {
  const { dispatch: flightDispatch } = useFlightDataContext();
  const { dispatch: noiseDispatch } = useNmtContext();
  const {
    state: { currentTimestamp, isLive, timestamps, maxPlayableTimestamp },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  const restartPlayback = () => {
    if (!isLive) {
      return;
    }

    const latestTimestamp = timestamps.length > 0 ? timestamps[timestamps.length - 1] : null;
    const restartTime = latestTimestamp - PLAYBACK_BUFFER_SIZE;
    const timeDifference = Date.now() - currentTimestamp;

    if (timeDifference > BACK_TO_THE_FUTURE_AFTER_USER_RETURNS) {
      console.log(`Reconnecting manually!`);
      // Ensure the connections started before dispatching actions
      Promise.all([startFlightConnection(), startNoiseConnection()]).catch(error => {
        console.error('Error starting connections:', error);
      });
    }

    if (timeDifference > RESTART_WAIT_TIME_AFTER_USER_RETURNS) {
      console.log(
        `RESTART: User returned to the tab: ${new Date(
          currentTimestamp
        ).toISOString()} is ${timeDifference / 1000}s behind.`
      );
      flightDispatch({ type: FlightDataActionType.RESTART });
      noiseDispatch({ type: NmtActionType.RESET_SAMPLE_DATA });
      playbackDispatch({ type: PlaybackActionType.RESTART });
      return;
    }

    if (timeDifference > BACK_TO_THE_FUTURE_AFTER_USER_RETURNS) {
      console.log(
        `BUMP BACK: Current timestamp ${new Date(
          currentTimestamp
        ).toISOString()} is ${timeDifference / 1000}s behind. Fast-forwarding to: ${new Date(
          Math.max(maxPlayableTimestamp, restartTime)
        ).toISOString()}`
      );
      playbackDispatch({
        type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
        payload: Math.max(maxPlayableTimestamp, restartTime),
      });
    }
  };

  return restartPlayback;
};

export default useRestartPlayback;
