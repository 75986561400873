import GLMap from 'react-map-gl';
import React, { useCallback, useState } from 'react';
import { MapActionType, MapEventTypes, useMapContext } from 'src/@realtime/contexts/map';
import * as turf from '@turf/turf';
import { parseUrlHash } from 'src/@realtime/utils';

interface MapProps extends React.ComponentProps<typeof GLMap> {
  latitude: number;
  longitude: number;
  zoom: number;
  apiKey: string;
  maxBoundsDistance?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Map: React.FC<MapProps> = ({
  latitude,
  longitude,
  zoom,
  apiKey,
  maxBoundsDistance,
  style,
  children,
  ...rest
}) => {
  const {
    state: { selectedMapStyle, mapEvents },
    dispatch,
  } = useMapContext();

  const [viewState, setViewState] = useState(() => {
    const hashState = rest.hash ? parseUrlHash() : null;
    return {
      longitude: hashState?.longitude || longitude,
      latitude: hashState?.latitude || latitude,
      zoom: hashState?.zoom || zoom,
    };
  });

  const triggerMapEvents = useCallback(
    (e: mapboxgl.MapMouseEvent | mapboxgl.MapLayerMouseEvent, eventType: MapEventTypes) => {
      mapEvents.filter(({ type }) => type === eventType).forEach(({ event }) => event(e));
    },
    [mapEvents]
  );

  const handleMapClick = useCallback(
    (e: mapboxgl.MapMouseEvent) => triggerMapEvents(e, MapEventTypes.CLICK),
    [triggerMapEvents]
  );

  const handleMapHover = useCallback(
    (e: mapboxgl.MapLayerMouseEvent) => triggerMapEvents(e, MapEventTypes.HOVER),
    [triggerMapEvents]
  );

  const handleMapLoad = useCallback(() => {
    dispatch({ type: MapActionType.SET_MAP_LOADED });
  }, [dispatch]);

  const onMove = useCallback(
    ({ viewState: newViewState }: { viewState: typeof viewState }) => {
      if (maxBoundsDistance) {
        const geofence = turf.circle([longitude, latitude], maxBoundsDistance, {
          units: 'kilometers',
        });
        if (
          !turf.booleanPointInPolygon([newViewState.longitude, newViewState.latitude], geofence)
        ) {
          return;
        }
      }
      setViewState(newViewState);
    },
    [latitude, longitude, maxBoundsDistance]
  );

  return (
    <GLMap
      {...viewState}
      onMove={onMove}
      onLoad={handleMapLoad}
      onClick={handleMapClick}
      onMouseMove={handleMapHover}
      mapboxAccessToken={apiKey}
      style={style}
      mapStyle={selectedMapStyle.url}
      {...rest}>
      {children}
    </GLMap>
  );
};
