/**
 * Converts an ISO 8601 date string with an offset to a UTC timestamp in milliseconds.
 *
 * @param {string} isoString - The ISO 8601 date string (e.g., "2025-02-26T00:10:17+10:00").
 * @returns {number} - The UTC timestamp in milliseconds.
 */
export const isoToTimestamp = (isoString: string): number => {
  const fixedIsoString = isoString.replace(' ', '+'); // Fix decoded space issue if applicable
  return Date.parse(fixedIsoString); // Returns milliseconds
};
