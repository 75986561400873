import React, { useCallback } from 'react';
import { ControlPosition, useControl } from 'react-map-gl';

interface MapControlButtonProps {
  title: string;
  onClick: () => void;
  iconName: string;
  buttonRef?: (instance: HTMLButtonElement | null) => void;
  className?: string;
  position?: ControlPosition;
}
class CustomControl {
  private container: HTMLDivElement;
  private button: HTMLButtonElement;

  constructor(
    onClick: () => void,
    title: string,
    iconName: string,
    className: string,
    buttonRef?: (instance: HTMLButtonElement | null) => void
  ) {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.ariaLabel = title;
    this.button.title = title;
    this.button.className = className;
    this.button.onclick = onClick;

    if (buttonRef) {
      buttonRef(this.button); // Attach ref to button
    }

    const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    icon.setAttribute('height', '20');
    icon.setAttribute('width', '20');
    icon.setAttribute('class', 'mosaic-icon');

    const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${iconName}`);
    icon.appendChild(use);

    this.button.appendChild(icon);
    this.container.appendChild(this.button);
  }

  onAdd() {
    return this.container;
  }

  onRemove() {
    this.container.remove();
  }
}

export const MapControlButton: React.FC<MapControlButtonProps> = ({
  title,
  onClick,
  iconName,
  buttonRef,
  className = '',
  position = 'top-right',
}) => {
  const createControl = useCallback(
    () => new CustomControl(onClick, title, iconName, className, buttonRef),
    [onClick, title, iconName, className, buttonRef]
  );

  useControl(createControl, { position });

  return null;
};
