// Playback status types
export enum PlaybackStatus {
  Playing = 'playing',
  Paused = 'paused',
  Buffering = 'buffering',
}

// Playback modes
export enum PlaybackMode {
  None = 'none',
  Historical = 'historical',
  Realtime = 'realtime',
}

// Playback state structure
export interface PlaybackState {
  timestamps: number[]; // Array of received timestamps
  playbackStatus: PlaybackStatus; // Current playback status
  currentTimestamp: number; // Currently playing timestamp
  minTimestamp: number; // Minimum timestamp
  maxTimestamp: number; // Maximum timestamp
  maxPlayableTimestamp: number; // Maximum timestamp that can be played
  isLive: boolean; // Is the playback in live mode
  playbackSpeed: number; // Playback speed
  playbackMode: PlaybackMode; // Is the playback in realtime mode or historical
  realtimeHistory: number; // History to load from realtime endpoint in seconds
}

// Action types
export enum PlaybackActionType {
  ADD_TIMESTAMP = 'ADD_TIMESTAMP',
  SET_PLAYBACK_STATUS = 'SET_PLAYBACK_STATUS',
  SET_CURRENT_TIMESTAMP = 'SET_CURRENT_TIMESTAMP',
  SET_PLAYBACK_MODE = 'SET_REALTIME_MODE',
  ADVANCE_TIMESTAMP = 'ADVANCE_TIMESTAMP',
  SET_IS_LIVE = 'SET_IS_LIVE',
  PRUNE_OLD_TIMESTAMPS = 'PRUNE_OLD_TIMESTAMPS',
  RESTART = 'RESTART',
  SET_PLAYBACK_SPEED = 'SET_PLAYBACK_SPEED',
  SET_MIN_TIMESTAMP = 'SET_MIN_TIMESTAMP',
  SET_MAX_TIMESTAMP = 'SET_MAX_TIMESTAMP',
  SET_MAX_PLAYABLE_TIMESTAMP = 'SET_MAX_PLAYABLE_TIMESTAMP',
  SET_REALTIME_HISTORY = 'SET_REALTIME_HISTORY',
}

export type PlaybackAction =
  | { type: PlaybackActionType.ADD_TIMESTAMP; payload: number }
  | { type: PlaybackActionType.SET_PLAYBACK_STATUS; payload: PlaybackStatus }
  | { type: PlaybackActionType.SET_CURRENT_TIMESTAMP; payload: number | null }
  | { type: PlaybackActionType.SET_PLAYBACK_MODE; payload: PlaybackMode }
  | { type: PlaybackActionType.ADVANCE_TIMESTAMP }
  | { type: PlaybackActionType.SET_IS_LIVE; payload: boolean }
  | { type: PlaybackActionType.PRUNE_OLD_TIMESTAMPS; payload: number }
  | { type: PlaybackActionType.SET_PLAYBACK_SPEED; payload: number }
  | { type: PlaybackActionType.RESTART }
  | { type: PlaybackActionType.SET_MIN_TIMESTAMP; payload: number }
  | { type: PlaybackActionType.SET_MAX_TIMESTAMP; payload: number }
  | { type: PlaybackActionType.SET_MAX_PLAYABLE_TIMESTAMP; payload: number }
  | { type: PlaybackActionType.SET_REALTIME_HISTORY; payload: number };
