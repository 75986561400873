import { RealtimeLayerIds } from 'src/@realtime/constants';
import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';
import { CircleLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl';

export const activeNmtLayerStyle: CircleLayerSpecification = {
  source: RealtimeLayerIds.nmtActive,
  id: RealtimeLayerIds.nmtActive,
  type: 'circle',
  paint: {
    'circle-color': [
      'step',
      ['get', 'noiseLevel'],
      sassVars['noisedb-30-35'],
      55,
      sassVars['noisedb-55-60'],
      60,
      sassVars['noisedb-60-65'],
      65,
      sassVars['noisedb-65-70'],
      70,
      sassVars['noisedb-70-75'],
      75,
      sassVars['noisedb-75-80'],
      80,
      sassVars['noisedb-80-plus'],
    ],
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      ['step', ['get', 'noiseLevel'], 2, 55, 3, 60, 3.5, 75, 4, 80, 4],
      11,
      ['step', ['get', 'noiseLevel'], 5, 35, 7, 55, 8, 60, 12, 75, 18, 80, 18],
    ],
  },
};

export const inActiveNmtLayerStyle = (
  iconColor = '#5a6872',
  iconSize = 0.5
): SymbolLayerSpecification => ({
  id: RealtimeLayerIds.nmtInActive,
  type: 'symbol',
  source: RealtimeLayerIds.nmtActive,
  layout: {
    'icon-image': 'nmt-square',
    'icon-size': iconSize,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 0.8,
    'icon-color': iconColor,
  },
  filter: ['==', ['get', 'isInActive'], false],
});

export const NmtTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;
