import React, { useEffect, useRef } from 'react';
import {
  NmtActionType,
  useNmtContext,
  RealtimeConnectionType,
  useRealtimeConnectionContext,
  usePlaybackContext,
} from 'src/@realtime/contexts';
import {
  useLoadBatch,
  usePruneData,
  useSignalrConnection,
  useSignalrSubscription,
} from 'src/@realtime/hooks';
import { LOADING_BATCH_TIME, STREAM_HUB_NAMES, STREAM_METHOD_NAMES } from 'src/@realtime/constants';
import {
  StreamedSubscriptionParameters,
  StreamedFlightPoints,
  StreamedFlightPoint,
  StreamedNoiseSamples,
  StreamedNoiseSample,
} from 'src/@realtime/types';
import { createSignalrConfig, createBufferHandler } from './utils';
import { useLanguageSelectors } from 'src/app/reducers';
import { connectionToast } from './styles';
import useTabVisibility from 'src/@realtime/hooks/playback/useTabVisibility';
import useRestartPlayback from 'src/@realtime/hooks/playback/useRestartPlayback';

export const RealtimeConnections: React.FC = () => {
  const loadBatch = useLoadBatch();
  const { dispatch: nmtDispatch } = useNmtContext();
  const { setConnection } = useRealtimeConnectionContext();
  const {
    state: { realtimeHistory },
  } = usePlaybackContext();

  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      realtime: { messages, types },
    },
  } = languageSelectors.getLanguage() as {
    screens: {
      realtime: {
        messages: {
          signlrReconnectSuccess: string;
          signlrReconnectAttempt: string;
          signlrReconnectError: string;
        };
        types: {
          flight: string;
          noise: string;
        };
      };
    };
  };

  const {
    connection: flightConnection,
    manuallyStopped: flightManuallyStopped,
  } = useSignalrConnection(
    createSignalrConfig({
      type: RealtimeConnectionType.Flight,
      streamName: STREAM_HUB_NAMES.REAL_TIME_FLIGHT,
      onClose: () =>
        connectionToast({
          type: RealtimeConnectionType.Flight,
          message: `${types.flight} ${messages.signlrReconnectError}`,
          intent: 'danger',
        }),
      onStartError: () =>
        connectionToast({
          type: RealtimeConnectionType.Flight,
          message: `${types.flight} ${messages.signlrReconnectError}`,
          intent: 'danger',
        }),
    })
  );

  const {
    connection: noiseConnection,
    manuallyStopped: noiseManuallyStopped,
  } = useSignalrConnection(
    createSignalrConfig({
      type: RealtimeConnectionType.Noise,
      streamName: STREAM_HUB_NAMES.REAL_TIME_NOISE,
      onClose: () =>
        connectionToast({
          type: RealtimeConnectionType.Noise,
          message: `${types.noise} ${messages.signlrReconnectError}`,
          intent: 'danger',
        }),
      onStartError: () =>
        connectionToast({
          type: RealtimeConnectionType.Noise,
          message: `${types.noise} ${messages.signlrReconnectError}`,
          intent: 'danger',
        }),
    })
  );

  usePruneData();
  const restartPlayback = useRestartPlayback(
    () => flightConnection.start(),
    () => noiseConnection.start()
  );
  useTabVisibility(restartPlayback);

  const flightPointBufferRef = useRef<Record<string, StreamedFlightPoint[]>>({});
  const noiseSampleBufferRef = useRef<Record<string, StreamedNoiseSample[]>>({});
  const flightBufferTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const noiseBufferTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const flightBufferHandler = createBufferHandler<StreamedFlightPoint>(
    flightPointBufferRef,
    buffer => {
      Object.keys(buffer).forEach(trackId => {
        loadBatch(buffer[trackId], trackId);
      });
    },
    flightBufferTimeoutRef,
    LOADING_BATCH_TIME
  );

  const noiseBufferHandler = createBufferHandler<StreamedNoiseSample>(
    noiseSampleBufferRef,
    buffer => {
      Object.keys(buffer).forEach(deviceLocationId => {
        nmtDispatch({
          type: NmtActionType.ADD_NOISE_SAMPLES,
          payload: buffer[deviceLocationId],
        });
      });
    },
    noiseBufferTimeoutRef,
    LOADING_BATCH_TIME + 2500 // Offset by 2.5 seconds
  );

  useEffect(() => {
    if (flightConnection) {
      setConnection(flightConnection, RealtimeConnectionType.Flight);
    }
    if (noiseConnection) {
      setConnection(noiseConnection, RealtimeConnectionType.Noise);
    }
  }, [flightConnection, noiseConnection]);

  useSignalrSubscription<StreamedFlightPoints, StreamedSubscriptionParameters>({
    historySeconds: realtimeHistory,
    connection: flightConnection || null,
    subscriptionParameters: flightConnection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_TRACK_POINTS,
              { historySeconds: realtimeHistory },
            ],
            subscriber: {
              next: (data: StreamedFlightPoints) => {
                flightBufferHandler.addToBuffer(data.point.trackId, data.point);
              },
              complete: () => {},
              error: e => console.log,
            },
          },
        ]
      : [],
  });

  useSignalrSubscription<StreamedNoiseSamples, StreamedSubscriptionParameters>({
    historySeconds: realtimeHistory,
    connection: noiseConnection || null,
    subscriptionParameters: noiseConnection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_NOISE_SAMPLES,
              { historySeconds: realtimeHistory },
            ],
            subscriber: {
              next: (data: StreamedNoiseSamples) => {
                noiseBufferHandler.addToBuffer(
                  data.sample.deviceLocationId.toString(),
                  data.sample
                );
              },
              complete: () => {},
              error: e => console.log,
            },
          },
        ]
      : [],
  });

  useEffect(
    () => () => {
      flightManuallyStopped.current = true; // Set the flag to true when unmounting
      noiseManuallyStopped.current = true; // Set the flag to true when unmounting
    },
    []
  );

  useEffect(
    () => () => {
      if (flightBufferTimeoutRef.current) {
        clearTimeout(flightBufferTimeoutRef.current);
        flightBufferTimeoutRef.current = null;
      }
      if (noiseBufferTimeoutRef.current) {
        clearTimeout(noiseBufferTimeoutRef.current);
        noiseBufferTimeoutRef.current = null;
      }
      flightBufferHandler.flushBuffer(); // Flush remaining flight points on unmount
      noiseBufferHandler.flushBuffer(); // Flush remaining noise samples on unmount
    },
    []
  );
  return null;
};
