import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { mapReducer } from './reducer';
import { MapState, MapAction } from './types';
import { MapStyles } from 'src/@realtime/constants';

// Initial state
export const initialState: MapState = {
  isMapLoaded: false,
  isSpritesLoaded: false,
  selectedMapStyle: MapStyles.Basic,
  isMapSettingsOpen: false,
  stateToUpdate: null,
  mapEvents: [],
  isMeasuring: false,
};

// Context and dispatch setup
const MapContext = createContext<{
  state: MapState;
  dispatch: Dispatch<MapAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const MapProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(mapReducer, initialState);
  return <MapContext.Provider value={{ state, dispatch }}>{children}</MapContext.Provider>;
};

// Custom hook to access playback state
export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMapContext must be used within a MapContext');
  }
  return context;
};
