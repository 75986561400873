import React, { useMemo } from 'react';
import { Source, Layer } from 'react-map-gl';
import { flightLayerStyle, shadowLayerStyle } from './styles';
import { useAddMapEventFunction } from 'src/@realtime/hooks';
import { loadMapSprites, queryMapFeaturesById } from 'src/@realtime/utils';

import { PulsingSelectionLayer } from '../flightSelectionLayer/pulsingSelectionLayer';
import { RealtimeLayerIds } from 'src/@realtime/constants';
import { FlightDataActionType, useFlightDataContext } from 'src/@realtime/contexts';
import { MapActionType, MapEventTypes, useMapContext } from 'src/@realtime/contexts/map';
import { useBuildFlightData } from './hooks';
import { useConfigSelectors } from 'src/app/reducers';
import { SelectedFlightTag } from './selectedFlightTag';

export const FlightLayer = (): JSX.Element => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const {
    state: { selectedTracks },
    dispatch: flightDispatch,
  } = useFlightDataContext();
  const {
    state: { isSpritesLoaded },
    dispatch: mapDispatch,
  } = useMapContext();

  const flightData = useBuildFlightData();

  const clickFunction = useMemo(
    () => ({
      id: 'my-map-click',
      type: MapEventTypes.CLICK,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesById(target, point, RealtimeLayerIds.flightLayer);
        if (features[0]) {
          console.log('Selected track:', features[0].properties);
          flightDispatch({
            type: FlightDataActionType.SELECT_TRACK,
            payload: features[0].properties?.trackId as string,
          });
        }
      },
    }),
    []
  );

  useAddMapEventFunction(clickFunction);

  // Collect the selected flights info required for Selections
  const selectedFeatures = useMemo(
    () =>
      flightData.features.filter(feature => selectedTracks.includes(feature.properties?.trackId)),
    [flightData, selectedTracks]
  );

  loadMapSprites(selectedTrackTheme, () => {
    mapDispatch({
      type: MapActionType.SET_IS_SPRITES_LOADED,
      payload: true,
    });
  });

  return (
    <>

      {isSpritesLoaded && (
        <Source id={RealtimeLayerIds.flightLayer} type="geojson" data={flightData}>
          <Layer {...shadowLayerStyle} />
          <Layer {...flightLayerStyle} />
        </Source>
      )}
      {selectedTracks.length > 0 &&
        <>
          <PulsingSelectionLayer
            selectedTracks={selectedTracks}
            features={{
              type: 'FeatureCollection',
              features: selectedFeatures, // To know the position of the AC the user selected we pass the selectedFeatures containing coordinates.
            }}
          />
          <SelectedFlightTag trackIds={selectedTracks} />
        </>
      }
    </>
  );
};
