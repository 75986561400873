import { HubConnection, ISubscription } from '@microsoft/signalr';
import { useCallback, useEffect, useRef } from 'react';

import { StreamParameters, SubscriptionParameters } from './types';

const useSubscriptionCallbacks = <R, SP extends StreamParameters>(
  connection?: HubConnection | null
) => {
  const subscriptions = useRef<ISubscription<R>[]>([]);

  const handleDisposeSubscriptions = useCallback(() => {
    subscriptions.current?.forEach(subscription => {
      if (!subscription) {
        return;
      }
      subscription.dispose();
    });
  }, []);

  const handleSetSubscriptions = useCallback(
    (subscriptionParameters: SubscriptionParameters<R, SP>) => {
      if (!connection) {
        return;
      }
      subscriptions.current = subscriptionParameters.map(
        ({ streamParameters: [methodName, ...rest], subscriber }) => {
          const stream = connection.stream<R>(methodName, ...rest);
          return stream.subscribe(subscriber);
        }
      );
    },
    [connection]
  );

  return {
    handleDisposeSubscriptions,
    handleSetSubscriptions,
  };
};

export const useSignalrSubscription = <R, SP extends StreamParameters>({
  connection,
  subscriptionParameters,
  historySeconds,
}: {
  connection?: HubConnection | null;
  subscriptionParameters: SubscriptionParameters<R, SP>;
  historySeconds?: number;
}) => {
  const connectionId = connection?.connectionId;
  const { handleDisposeSubscriptions, handleSetSubscriptions } = useSubscriptionCallbacks<R, SP>(
    connection
  );

  useEffect(() => {
    handleDisposeSubscriptions();
    handleSetSubscriptions(subscriptionParameters);
  }, [connection, connectionId, historySeconds]);

  useEffect(
    () => () => {
      handleDisposeSubscriptions();
    },
    [handleDisposeSubscriptions]
  );
};
