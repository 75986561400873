import { useCallback } from 'react';
import {
  FlightDataActionType,
  FlightPosition,
  NmtActionType,
  NoiseSample,
  PlaybackActionType,
  PlaybackStatus,
  useFlightDataContext,
  useNmtContext,
  usePlaybackContext,
} from 'src/@realtime/contexts';
import { GetNoiseMonitorSamplesByTimeReponse, OperationItem } from 'src/@realtime/queries';

import { useConfigSelectors } from 'src/app/reducers';
import { getOperationTypeColor } from 'src/utils';
import { DateTime } from 'luxon';
import { PLAYBACK_HISTORY_SECONDS } from 'src/@realtime/constants';

export const useAddFlight = () => {
  const { dispatch: flightDispatch } = useFlightDataContext();
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  return useCallback(
    (operation: OperationItem) => {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      const {
        operationType,
        remoteAirportId,
        id,
        acid,
        beaconCode,
        aircraftType,
        aircraftCategory,
        tailNumber,
        airportId,
      } = operation;

      const operationColor = getOperationTypeColor(selectedTrackTheme, operationType);
      flightDispatch({
        type: FlightDataActionType.ADD_FLIGHT,
        payload: {
          trackId: id,
          beaconCode,
          destination: remoteAirportId,
          acId: acid,
          aircraftType,
          aircraftCategory,
          tailNumber,
          origin: airportId,
          operationType,
          operationColor,
        },
      });
    },
    [flightDispatch]
  );
};

export const useAddFlightPath = () => {
  const { dispatch: flightDispatch } = useFlightDataContext();
  return useCallback(
    (positions: FlightPosition[], trackId: string) => {
      flightDispatch({
        type: FlightDataActionType.ADD_POSITIONS,
        payload: {
          trackId,
          positions,
        },
      });
    },
    [flightDispatch]
  );
};

export const useSetTime = () => {
  const { dispatch: playbackDispatch } = usePlaybackContext();
  return useCallback(
    (time: number) => {
      console.log('useSetTime');
      playbackDispatch({
        type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
        payload: time,
      });
      playbackDispatch({
        type: PlaybackActionType.SET_PLAYBACK_STATUS,
        payload: PlaybackStatus.Paused,
      });
    },
    [playbackDispatch]
  );
};

export const useResetRealtimeData = () => {
  const { dispatch: flightDispatch } = useFlightDataContext();
  const { dispatch: playbackDispatch } = usePlaybackContext();
  const { dispatch: noiseDispatch } = useNmtContext();

  return useCallback(
    ({
      flight = true,
      playback = true,
      noise = true,
    }: {
      flight?: boolean;
      playback?: boolean;
      noise?: boolean;
    }) => {
      if (flight) {
        flightDispatch({
          type: FlightDataActionType.RESTART,
        });
      }
      if (playback) {
        playbackDispatch({
          type: PlaybackActionType.RESTART,
        });
      }
      if (noise) {
        noiseDispatch({
          type: NmtActionType.RESET_SAMPLE_DATA,
        });
      }
    },
    [flightDispatch, playbackDispatch, noiseDispatch]
  );
};

export const useAddNoiseMonitorSamples = () => {
  const { dispatch: noiseDispatch } = useNmtContext();

  return useCallback(
    (noiseData: GetNoiseMonitorSamplesByTimeReponse) => {
      if (!noiseData || !noiseData.continuousNoiseSamplesByTimeRange) {
        return;
      }

      const points: Record<string, NoiseSample[]> = {};

      noiseData.continuousNoiseSamplesByTimeRange.forEach(item => {
        const { locationId, samples, startTime } = item;
        const startInMilliseconds = DateTime.fromISO(startTime).toMillis();

        points[locationId] = samples.map((sample, index) => ({
          deviceLocationId: locationId,
          time: DateTime.fromMillis(startInMilliseconds + index * 1000).toISO(),
          value: sample,
        }));
      });
      noiseDispatch({
        type: NmtActionType.ADD_HISTORICAL_SAMPLES,
        payload: points,
      });
    },
    [noiseDispatch]
  );
};

export const useSetTimeRange = () => {
  const { dispatch: playbackDispatch } = usePlaybackContext();

  return useCallback(
    (dateTime: DateTime) => {
      playbackDispatch({
        type: PlaybackActionType.SET_MIN_TIMESTAMP,
        payload: dateTime.toMillis(),
      });
      playbackDispatch({
        type: PlaybackActionType.SET_MAX_TIMESTAMP,
        payload: dateTime.plus({ seconds: PLAYBACK_HISTORY_SECONDS }).toMillis(),
      });
      playbackDispatch({
        type: PlaybackActionType.SET_MAX_PLAYABLE_TIMESTAMP,
        payload: dateTime.plus({ seconds: PLAYBACK_HISTORY_SECONDS }).toMillis(),
      });
      playbackDispatch({
        type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
        payload: dateTime.toMillis(),
      });
    },
    [playbackDispatch]
  );
};
