import { useEffect } from 'react';
import { MapEventType } from 'mapbox-gl';
import { useMapboxMapContext } from '../context/useMapboxMapContext';
import { MapRef } from 'react-map-gl';

export type MapEventHandler = Parameters<MapRef['on']>[2];

export const useMapEvent = (eventType: MapEventType, eventHandler: MapEventHandler) => {
  const { map } = useMapboxMapContext();
  useEffect(() => {
    map?.on(eventType, eventHandler);
    return () => {
      map?.off(eventType, eventHandler);
    };
  }, [eventHandler, eventType, map]);
};
