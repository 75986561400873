import { FlightPosition } from 'src/@realtime/contexts';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { TailLineStringProperties } from 'src/@realtime/types';
import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';
import { Position, nearestPointOnLine, point } from '@turf/turf';
import * as turf from '@turf/turf';
import { Units } from '@turf/helpers';

/**
 * Finds the Position object in the flightPositions array that is closest to the given timestamp.
 *
 * @param flightPositions
 * @param timestamp
 * @returns flightPositions and index of found position
 */ export const findPosition = (
  flightPositions: FlightPosition[],
  timestamp: number
): [FlightPosition | null, number] => {
  const len = flightPositions.length;
  if (
    len === 0 ||
    timestamp < flightPositions[0].time ||
    timestamp > flightPositions[len - 1].time
  ) {
    return [null, -1];
  }

  // Try direct index based on time proportion
  const startTime = flightPositions[0].time;
  const timeRange = flightPositions[len - 1].time - startTime;
  const estimatedIndex = Math.floor(((timestamp - startTime) / timeRange) * (len - 1));

  if (flightPositions[estimatedIndex].time === timestamp) {
    return [flightPositions[estimatedIndex], estimatedIndex];
  }

  // Quick linear search for nearby positions if close
  for (let i = Math.max(0, estimatedIndex - 2); i < Math.min(len, estimatedIndex + 3); i++) {
    if (flightPositions[i].time === timestamp) {
      return [flightPositions[i], i];
    }
  }

  let left = 0;
  let right = len - 1;

  while (left <= right) {
    const mid = (left + right) >>> 1; // Faster than Math.floor(mid)
    const midTime = flightPositions[mid].time;

    if (midTime === timestamp) {
      return [flightPositions[mid], mid];
    }
    if (midTime < timestamp) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return [null, -1];
};

export const interpolateFlightPath = (points: FlightPosition[]): FlightPosition[] => {
  if (points.length < 2) {
    return points;
  }

  const interpolatedPoints: FlightPosition[] = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const previous = points[i - 1];
    const current = points[i];

    // Calculate the number of intervals to fill
    const startTime = previous.time;
    const endTime = current.time;
    const missingIntervals = (endTime - startTime) / PLAYBACK_TICK_INTERVAL_MS - 1;

    // Calculate the increments for latitude, longitude, and altitude
    const latIncrement = (current.latitude - previous.latitude) / (missingIntervals + 1);
    const lngIncrement = (current.longitude - previous.longitude) / (missingIntervals + 1);
    const altIncrement = (current.altitude - previous.altitude) / (missingIntervals + 1);

    // Generate interpolated points
    for (let j = 1; j <= missingIntervals; j++) {
      const time = startTime + j * PLAYBACK_TICK_INTERVAL_MS;
      const latitude = previous.latitude + latIncrement * j;
      const longitude = previous.longitude + lngIncrement * j;
      const altitude = previous.altitude + altIncrement * j;
      const heading = calculateBearing(
        [
          interpolatedPoints[interpolatedPoints.length - 1].longitude,
          interpolatedPoints[interpolatedPoints.length - 1].latitude,
        ],
        [longitude, latitude]
      );
      interpolatedPoints.push({ time, latitude, longitude, altitude, heading });
    }

    // Add the actual current point with updated heading
    interpolatedPoints.push({
      ...current,
      heading: calculateBearing(
        [previous.longitude, previous.latitude],
        [current.longitude, current.latitude]
      ),
    });
  }

  return interpolatedPoints;
};

const toRadians = (degrees: number) => degrees * (Math.PI / 180);
const toDegrees = (radians: number) => radians * (180 / Math.PI);

/**
 * Calculates the bearing between two geographical positions.
 *
 * @param previousPosition - The previous geographical position as a tuple [longitude, latitude].
 * @param currentPosition - The current geographical position as a tuple [longitude, latitude].
 * @returns The bearing in degrees from the previous position to the current position, normalized to a range of 0-360 degrees.
 */
export const calculateBearing = (previousPosition: Position, currentPosition: Position): number => {
  const lat1 = toRadians(previousPosition[1]);
  const lon1 = toRadians(previousPosition[0]);
  const lat2 = toRadians(currentPosition[1]);
  const lon2 = toRadians(currentPosition[0]);

  const dLon = lon2 - lon1;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  const bearing = toDegrees(Math.atan2(y, x));
  return parseFloat(((bearing + 360) % 360).toFixed(2)); // Normalize to 0-360 and round to 2 decimals
};

/**
 * Helper function to find the nearest point on a LineString and its altitude.
 *
 * @param event - The MapMouseEvent from react-map-gl.
 * @param lineFeature - A GeoJSON LineString feature with altitude in properties.
 * @returns The coordinates of the nearest point and its altitude or null.
 */

export const findNearestPoint = (
  event: MapLayerMouseEvent,
  lineFeature: GeoJSON.Feature<GeoJSON.LineString, TailLineStringProperties>
): [GeoJSON.Position | null, GeoJSON.Position | null] => {
  const { lngLat } = event;
  const turfPoint = point([lngLat.lng, lngLat.lat]);
  const nearestPoint = nearestPointOnLine(lineFeature, turfPoint);

  if (!nearestPoint || !nearestPoint.geometry || nearestPoint.properties.index === undefined) {
    return null;
  }

  // Extract index
  const index = nearestPoint.properties.index;
  const coordinates = lineFeature.geometry.coordinates;

  // Find previous point if possible
  const previousCoordinates = index > 0 ? coordinates[index - 1] : null;

  return [nearestPoint.geometry.coordinates, previousCoordinates];
};

/**
 * Calculate the distance between two points.
 *
 * @param start - The starting coordinates [longitude, latitude].
 * @param end - The ending coordinates [longitude, latitude].
 * @param selectedDistanceUnits - The selected unit system for the distance.
 * @returns A formatted string representing the distance and its unit.
 */
export const calculateDistance = (
  start: [number, number],
  end: [number, number],
  selectedDistanceUnits: 'US Customary' | 'ICAO Metric' | 'ICAO Alternative' | 'Local System'
): string => {
  const unitMetricTypes = {
    'US Customary': 'miles',
    'ICAO Metric': 'kilometers',
    'ICAO Alternative': 'nauticalmiles',
  };

  const unitMetricInitials: Record<
    'US Customary' | 'ICAO Metric' | 'ICAO Alternative' | 'Local System',
    string
  > = {
    'US Customary': 'mi',
    'ICAO Metric': 'km',
    'ICAO Alternative': 'nm',
    'Local System': 'm', // Ensure all cases are handled
  };

  const distanceUnit = unitMetricTypes[selectedDistanceUnits] as Units;
  const distance = turf.distance(turf.point(start), turf.point(end), { units: distanceUnit });
  // We show distances < 10km as metres, see https://envirosuitelimited.atlassian.net/wiki/spaces/AX/pages/1062469643/Presentation+of+units
  if (selectedDistanceUnits === 'ICAO Metric') {
    if (distance < 10) {
      return `${new Intl.NumberFormat().format(Math.round(distance * 1000))} m`; // Convert to meters, round, and format
    }
    return `${distance.toFixed(2)} km`; // Keep 2 decimal places for 10km+
  }
  const unitMetricInitial: string = unitMetricInitials[selectedDistanceUnits];
  return `${distance.toFixed(2)} ${unitMetricInitial}`;
};

export const convertSpeed = (
  speedKnots: number,
  selectedSpeedUnits: 'US Customary' | 'ICAO Metric' | 'ICAO Alternative' | 'Local System'
): string => {
  const speedUnitTypes: Record<
    'US Customary' | 'ICAO Metric' | 'ICAO Alternative' | 'Local System',
    { unit: string; conversionFactor: number }
  > = {
    'US Customary': { unit: 'mph', conversionFactor: 1.15078 }, // Knots to mph
    'ICAO Metric': { unit: 'km/h', conversionFactor: 1.852 }, // Knots to km/h
    'ICAO Alternative': { unit: 'kn', conversionFactor: 1 }, // No conversion needed
    'Local System': { unit: 'kn', conversionFactor: 1 }, // No conversion needed
  };

  const { unit, conversionFactor } = speedUnitTypes[selectedSpeedUnits];
  const convertedSpeed = speedKnots * conversionFactor;

  return `${convertedSpeed.toFixed(0)} ${unit}`;
};

export const smoothVelocityKnFast = (positions: FlightPosition[]): number | null => {
  if (positions.length < 2) {
    return null;
  } // Need at least 2 positions to calculate speed

  let totalSpeed = 0;
  let count = 0;

  for (let i = 1; i < positions.length; i++) {
    // Iterate over pairs
    const prev = positions[i - 1];
    const curr = positions[i];

    const speed = calculateVelocityKn(prev, curr);
    if (speed > 0) {
      // Ensure we are not averaging incorrect 0 values
      totalSpeed += speed;
      count++;
    }
  }

  return count > 0 ? totalSpeed / count : null; // Return average speed
};

// export const smoothVelocityKnFast = (positions: FlightPosition[], index: number, windowSize: number = 5): number | null => {
//   if (index < windowSize) { return null; }

//   let totalWeight = 0;
//   let weightedSpeedSum = 0;

//   for (let i = 0; i < windowSize; i++) {
//     const prev = positions[index - i - 1] ?? positions[index - i]; // Avoid out-of-bounds
//     const curr = positions[index - i];

//     const weight = (windowSize - i) / windowSize; // Give more weight to recent data
//     const speed = calculateVelocityKn(prev, curr);

//     weightedSpeedSum += speed * weight;
//     totalWeight += weight;
//   }

//   return weightedSpeedSum / totalWeight;
// };

function normalizeUnixTimeToSeconds(timestamp: number): number {
  // If it's in milliseconds, convert to seconds
  return timestamp > 1e12 ? Math.floor(timestamp / 1000) : timestamp;
}

export const calculateVelocityKn = (point1, point2) => {
  if (!point1 || !point2) {
    // console.warn("Invalid points for velocity calculation:", point1, point2);
    return null;
  }

  let { latitude: lat1, longitude: lon1, altitude: alt1, time: time1 } = point1;
  let { latitude: lat2, longitude: lon2, altitude: alt2, time: time2 } = point2;

  // Convert milliseconds to seconds
  time1 = normalizeUnixTimeToSeconds(time1);
  time2 = normalizeUnixTimeToSeconds(time2);

  // Ensure time1 < time2
  if (time1 > time2) {
    [lat1, lon1, alt1, time1, lat2, lon2, alt2, time2] = [
      lat2,
      lon2,
      alt2,
      time2,
      lat1,
      lon1,
      alt1,
      time1,
    ];
  }

  // Convert altitude from feet to nautical miles
  const alt1Nm = alt1 / 6076.12;
  const alt2Nm = alt2 / 6076.12;

  // Calculate the Haversine distance
  const surfaceDistanceNm = haversine(lat1, lon1, lat2, lon2);

  // Calculate the vertical distance
  const verticalDistanceNm = Math.abs(alt1Nm - alt2Nm);

  // Calculate the total 3D distance
  const totalDistanceNm = Math.sqrt(surfaceDistanceNm ** 2 + verticalDistanceNm ** 2);

  // Check if time difference is valid
  const timeDifferenceHours = (time2 - time1) / 3600;
  if (timeDifferenceHours <= 0) {
    // console.warn("Invalid time difference (zero or negative)", { time1, time2 });
    return null; // Avoid division by zero
  }

  // Calculate the velocity in knots
  const velocityKn = totalDistanceNm / timeDifferenceHours;

  return velocityKn;
};

const haversine = (lat1, lon1, lat2, lon2) => {
  // Earth's radius in nautical miles
  const R = 3440.065;

  // Convert degrees to radians
  const toRadians = degrees => degrees * (Math.PI / 180);

  // Convert latitude and longitude to radians
  const phi1 = toRadians(lat1);
  const phi2 = toRadians(lat2);
  const deltaPhi = toRadians(lat2 - lat1);
  const deltaLambda = toRadians(lon2 - lon1);

  // Haversine formula
  const a =
    Math.sin(deltaPhi / 2) ** 2 + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in nautical miles
  return R * c;
};
