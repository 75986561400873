import React, { useEffect } from 'react';
import { useGetUrlDate, useMapConfigsWrapper } from 'src/@realtime/hooks';
import { Map, MapControls, RealtimeLayers } from 'src/@realtime/components';
import { DEFAULT_BBOX_DISTANCE } from 'src/constants';
import { useMapContext } from 'src/@realtime/contexts/map';
import { setTabTitle } from 'src/utils';
import { useLanguageSelectors } from 'src/app/reducers';
import { useSetPlaybackMode } from 'src/@realtime/hooks';
import mapboxgl from 'mapbox-gl';
import { Intent } from '@blueprintjs/core';
import { displayToast } from '@ems/client-design-system';

export const RealtimePage: React.FC = () => {
  const check = useSetPlaybackMode();
  const getUrlDate = useGetUrlDate();

  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      realtime: {
        title: realtimePageTitle,
        messages: { webGLRequired },
      },
    },
  } = languageSelectors.getLanguage();

  const {
    centre: { latitude, longitude },
    zoom,
    mapAccessToken,
    maxZoom,
    minZoom,
  } = useMapConfigsWrapper();

  setTabTitle(realtimePageTitle);

  useEffect(() => {
    check(getUrlDate());

    // Check for WebGL support and notify user if not supported
    if (!mapboxgl.supported()) {
      displayToast({
        message: webGLRequired as string,
        intent: Intent.DANGER,
        timeout: 0, // Persistent
        icon: 'warning-sign',
      });
    }
  }, []);

  const {
    state: { isMapLoaded },
  } = useMapContext();

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Map
        hash
        pitch={0}
        bearing={0}
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        apiKey={mapAccessToken}
        maxZoom={maxZoom}
        minZoom={minZoom}
        maxBoundsDistance={DEFAULT_BBOX_DISTANCE}>
        <MapControls resetView mapSettings measureTool />
        {isMapLoaded && <RealtimeLayers />}
      </Map>
    </div>
  );
};
