import { useCallback } from 'react';
import {
  PlaybackActionType,
  PlaybackMode,
  PlaybackStatus,
  usePlaybackContext,
} from 'src/@realtime/contexts';
import { useUrlParam } from '../url/useUrlParam';
import { DateTime } from 'luxon';
import { PLAYBACK_HISTORY_SECONDS, SIGNALR_CACHE_THRESHOLD_MINUTES } from 'src/@realtime/constants';

export const useSetPlaybackMode = () => {
  const { updateUrlParam } = useUrlParam();
  const { dispatch: playbackDispatch } = usePlaybackContext();

  const updatePlayback = useCallback(
    (date?: DateTime | null) => {
      playbackDispatch({
        type: PlaybackActionType.SET_PLAYBACK_MODE,
        payload: PlaybackMode.None,
      });
      playbackDispatch({
        type: PlaybackActionType.SET_PLAYBACK_STATUS,
        payload: PlaybackStatus.Buffering,
      });

      if (date && date.isValid) {
        const now = DateTime.now().setZone(date.zoneName);
        const diffInMinutes: number = now.diff(date, 'minutes').minutes;
        const diffInSeconds: number = now.diff(date, 'seconds').seconds;
        const isOlderThanThreshold = diffInMinutes > SIGNALR_CACHE_THRESHOLD_MINUTES;

        updateUrlParam('date', date.toFormat("yyyy-MM-dd'T'HH:mm:ss"));
        if (isOlderThanThreshold) {
          console.log(
            `Date is older than ${SIGNALR_CACHE_THRESHOLD_MINUTES} minutes. Difference: ${diffInSeconds.toFixed(
              1
            )} secs. Set to graphql API`
          );
          playbackDispatch({
            type: PlaybackActionType.SET_MIN_TIMESTAMP,
            payload: date.toMillis(),
          });
          playbackDispatch({
            type: PlaybackActionType.SET_MAX_TIMESTAMP,
            payload: date.plus({ seconds: PLAYBACK_HISTORY_SECONDS }).toMillis(),
          });
          playbackDispatch({
            type: PlaybackActionType.SET_MAX_PLAYABLE_TIMESTAMP,
            payload: date.plus({ seconds: PLAYBACK_HISTORY_SECONDS }).toMillis(),
          });
          playbackDispatch({
            type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
            payload: date.toMillis(),
          });
          playbackDispatch({
            type: PlaybackActionType.SET_IS_LIVE,
            payload: false,
          });

          playbackDispatch({
            type: PlaybackActionType.SET_PLAYBACK_MODE,
            payload: PlaybackMode.Historical,
          });
        }

        if (!isOlderThanThreshold) {
          console.log(
            `Date is within ${SIGNALR_CACHE_THRESHOLD_MINUTES} minutes. Difference: ${diffInSeconds.toFixed(
              1
            )} secs. Set to SignalR`
          );
          playbackDispatch({
            type: PlaybackActionType.SET_REALTIME_HISTORY,
            payload: Math.floor(diffInSeconds),
          });
          playbackDispatch({
            type: PlaybackActionType.SET_PLAYBACK_MODE,
            payload: PlaybackMode.Realtime,
          });
        }
      } else {
        console.log('Setting playback mode to realtime');
        playbackDispatch({
          type: PlaybackActionType.SET_PLAYBACK_MODE,
          payload: PlaybackMode.Realtime,
        });
      }
    },
    [playbackDispatch, updateUrlParam]
  );

  return updatePlayback;
};
