import styled from 'styled-components/macro';

// Types
import { CircleLayer, LineLayer } from 'react-map-gl';
import { InteractiveLayerId } from 'src/components/Map/hooks/useInteractiveLayerIds';

export const TaxiPathContainer = styled.div`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MAP_DEFAULT_COLOR = '#0a68eb';
export const MAP_SELECT_COLOR = '#fbb03b';
export const MAP_SELECT_COLOR_DARK = '#e89410';

export const MapboxStyleNodes: CircleLayer = {
  source: 'node-source',
  id: InteractiveLayerId.taxiPathNode,
  type: 'circle',
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      MAP_SELECT_COLOR,
      MAP_DEFAULT_COLOR,
    ],
    'circle-radius': ['step', ['zoom'], 10, 1, 15, 3, 5],
    'circle-stroke-color': '#fff',
    'circle-stroke-width': 2,
  },
};

export const MapboxStylePathMidpoint: CircleLayer = {
  source: `${InteractiveLayerId.drawableLineSegmentMidpoint}-source`,
  id: InteractiveLayerId.drawableLineSegmentMidpoint,
  type: 'circle',
  paint: {
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 15, 3, 20, 5],
    'circle-color': MAP_SELECT_COLOR,
  },
};

export const MapboxStylePathSegment: LineLayer = {
  source: `${InteractiveLayerId.drawableLineSegment}-source`,
  id: InteractiveLayerId.drawableLineSegment,
  type: 'line',
  paint: {
    'line-color': MAP_SELECT_COLOR,
    'line-dasharray': [2, 2],
    'line-width': 2,
  },
};

export const MapboxStylePathPoint: CircleLayer = {
  source: `${InteractiveLayerId.drawableLinePoint}-source`,
  id: InteractiveLayerId.drawableLinePoint,
  type: 'circle',
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#000',
      MAP_SELECT_COLOR,
    ],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 15, 5, 20, 7],
    'circle-stroke-color': '#fff',
    'circle-stroke-width': 2,
  },
};

export const MapboxStyleEditNode: CircleLayer = {
  source: `${InteractiveLayerId.drawablePoint}-source`,
  id: InteractiveLayerId.drawablePoint,
  type: 'circle',
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#000',
      MAP_SELECT_COLOR,
    ],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 15, 5, 20, 7],
    'circle-stroke-color': '#fff',
    'circle-stroke-width': 2,
  },
};
