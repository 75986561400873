import React from 'react';
import { TimeAndDateWrapper, StyledTimeDisplay, StyledDateDisplay } from './styles';
import { playbackTimeFormatShort, playbackDateFormatShort, getCurrentTime } from 'src/utils';
interface TimeDisplayProps {
  currentTimestamp: number;
  twelveHourFormat: boolean;
}

export const TimeDisplay: React.FC<TimeDisplayProps> = ({ currentTimestamp, twelveHourFormat }) => (
  <TimeAndDateWrapper>
    <StyledTimeDisplay>
      <span className="current-time">
        {currentTimestamp !== 0 && playbackTimeFormatShort(getCurrentTime(currentTimestamp), twelveHourFormat)}
      </span>
    </StyledTimeDisplay>
    <StyledDateDisplay>
      <span className="current-date">
        {currentTimestamp !== 0 && playbackDateFormatShort(currentTimestamp)}
      </span>
    </StyledDateDisplay>
  </TimeAndDateWrapper>
);
