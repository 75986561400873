// Apollo
import gql from 'graphql-tag';

export const GET_NOISE_MONITOR_LOCATIONS = gql`
  query getMonitorLocations {
    monitorLocationSummaries {
      totalCount
      items {
        locationId
        deviceId
        deviceName
        deviceDescription
        locationName
        locationDescription
        position {
          latitude
          longitude
          altitude
        }
        deviceType
        isActive
        lastData
      }
    }
  }
`;

export const GET_NOISE_MONITOR_SAMPLES_BY_TIME = gql`
  query getNoiseMonitorSamplesByTime(
    $startTime: DateTimeOffset!
    $endTime: DateTimeOffset!
    $sampleTypes: [SampleType]
  ) {
    continuousNoiseSamplesByTimeRange(
      startTime: $startTime
      endTime: $endTime
      filter: { sampleTypes: $sampleTypes }
    ) {
      deviceId
      duration
      endTime
      frequencyWeighting
      locationId
      samples
      startTime
      sampleType
    }
  }
`;
