import React from 'react';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';
import { useConfigSelectors } from 'src/app/reducers';
import { IMPERIAL_BASE_UNIT, METRIC_BASE_UNIT, UNIT_FOOT } from 'src/constants';
import { RealtimeMapTag } from 'src/@realtime/components';
import { FlightTagContentsWrapper, FlightTagContentsInfo, ContainerDiv, TextContainerDiv, OriDestDiv, ACIdDiv, AircraftTypeDiv, AltitudeDiv, SpeedDiv } from './styles';
import { AltitudeSource } from 'src/@realtime/constants';
import { getVerticalDistance } from 'src/utils';
import { findPosition, smoothVelocityKnFast, convertSpeed } from 'src/@realtime/utils';
// 1/4 Temporaily disable showing logos by iata_code until we get the AirLabs License in place.
// import { Image } from './styles'; // Add this Import Image into the Import from ./styles above
// import iataIcaoMappings from './IATAICAOMappings.json';

interface ToolTipSelectionLayerProps {
  coordinates: GeoJSON.Position;
  altitude?: number;
  altitudeSource: AltitudeSource;
  trackId: string;
  speed?: number;
}

// // 2/4 Temporaily disable showing logos by iata_code until we get 
// // Caching the dictionary (this runs only once when the module is imported)
// const iataDictionary: Record<string, string> = (() => {
//   const map: Record<string, string> = {};
//   for (const item of iataIcaoMappings) {
//     map[item.icao_code] = item.iata_code;
//   }
//   return map;
// })();

export const ToolTipSelectionLayer: React.FC<ToolTipSelectionLayerProps> = ({
  coordinates,
  altitude,
  altitudeSource,
  trackId,
  speed,
}) => {
  const units = useConfigSelectors().getUnits();
  const altitudeDistanceUnits = units.distanceVertical;
  const speedDistanceUnits = useConfigSelectors().getDistanceUnitsProfile() as
    | 'US Customary'
    | 'ICAO Metric'
    | 'ICAO Alternative'
    | 'Local System';

  const {
    state: { flightInfo, positions },
  } = useFlightDataContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  if (!flightInfo[trackId]) {
    return null;
  }

  const { acId, origin, destination, aircraftType } = flightInfo[trackId];

  // For flightpath tooltips we pass in the altitude, however for aircraft tooltip we dynamically get the altitude so when the mouse is motionless the altitude can change.
  if (altitudeSource === AltitudeSource.dynamic) {
    if (positions[trackId].length > 0) {

      // Get the array from the dictionary
      const flightPositions = positions[trackId] ?? [];

      // Find the position for the current timestamp.
      const [position, index] = findPosition(flightPositions, currentTimestamp);

      if (position) {
        altitude = position.altitude;

        // Find the previous and current flight positions to work out speed
        const prevPositions = flightPositions.slice(Math.max(0, index - 4), index + 1);
        if (prevPositions) {
          speed = smoothVelocityKnFast(prevPositions);
        }
      }
    }
  }

  const acAlt = getVerticalDistance(altitude || 0, {
    convertTo: altitudeDistanceUnits === UNIT_FOOT ? IMPERIAL_BASE_UNIT : METRIC_BASE_UNIT,
    convertFrom: 'ft',
    returnValueType: 'string',
  }) as string;

  // 3/4 Temporaily disable showing logos by iata_code until we get the AirLabs License in place.
  // const iata_code = acId && acId.length > 2 ? iataDictionary[acId.substring(0, 3)] : null;
  return (
    <RealtimeMapTag longitude={coordinates[0]} latitude={coordinates[1]} anchor={'top-left'}>
      <FlightTagContentsWrapper>
        <FlightTagContentsInfo>
          <ContainerDiv>
            {/* 4/4 Temporaily disable showing logos by iata_code until we get the AirLabs License in place. 
            {iata_code && <Image src={`https://airlabs.co/img/airline/s/${iata_code}.png`} alt={iata_code} />} */}
            <TextContainerDiv>
              <ACIdDiv>{acId || 'Unknown'}</ACIdDiv>
              {origin !== null && destination !== null && (
                <OriDestDiv>
                  {origin || 'N/A'} &#8594; {destination || 'N/A'}
                </OriDestDiv>
              )}
              {aircraftType && <AircraftTypeDiv>{aircraftType}</AircraftTypeDiv>}
              {altitude && altitude > 0 && acAlt && <AltitudeDiv>{acAlt}</AltitudeDiv>}
              {speed && speed > 0 && <SpeedDiv>{convertSpeed(speed, speedDistanceUnits)}</SpeedDiv>}
            </TextContainerDiv>
          </ContainerDiv>
        </FlightTagContentsInfo>
      </FlightTagContentsWrapper>
    </RealtimeMapTag>
  );
};
