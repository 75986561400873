import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Source, Layer } from 'react-map-gl';
import { selectedAircraftCircleStyle } from './styles';
import { RealtimeLayerIds } from 'src/@realtime/constants';
import { FeatureCollection } from 'geojson';

interface PulsingSelectionLayerProps {
  selectedTracks: string[];
  features: FeatureCollection;
}

export const PulsingSelectionLayer: React.FC<PulsingSelectionLayerProps> = ({
  selectedTracks,
  features,
}) => {

  if (!selectedTracks.length) {
    return null;
  }

  const [radius, setRadius] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const isMounted = useRef(true);

  useEffect(() => {
    let animationFrame: number;
    const startTime = performance.now();

    isMounted.current = true;
    const animate = (timestamp: number) => {
      if (!isMounted.current) { return; }
      const elapsed = (timestamp - startTime) % 2000; // 2-second cycle

      if (elapsed < 1000) {
        // First second: Growing from 0 to 45px, fading from 1 to 0
        const progress = elapsed / 1000;
        setRadius(Math.max(0, progress * 45)); // Ensures radius never goes below 0
        setOpacity(Math.min(1, 1 - progress)); // Ensures opacity never exceeds 1
      } else {
        // Second second: Invisible (reset to start position)
        setRadius(0);
        setOpacity(0);
      }

      animationFrame = requestAnimationFrame(animate);
    };

    animationFrame = requestAnimationFrame(animate);

    return () => {
      isMounted.current = false;
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  const filter = useMemo(
    () =>
      selectedTracks.length > 0 ? ['in', 'trackId', ...selectedTracks] : ['in', 'trackId', ''],
    [selectedTracks]
  );

  return (
    <Source id={RealtimeLayerIds.selectedAircraftCircle} type="geojson" data={features}>
      <Layer
        id={selectedAircraftCircleStyle.id}
        type={selectedAircraftCircleStyle.type}
        filter={filter}
        beforeId={RealtimeLayerIds.selectedTail}
        paint={{
          ...selectedAircraftCircleStyle.paint,
          'circle-radius': radius,
          'circle-radius-transition': { duration: 0 },
          'circle-opacity': opacity,
          'circle-opacity-transition': { duration: 0 },
          'circle-color': 'white',
          'circle-blur': 0.8,
        }}
      />
    </Source>
  );
};
