import { useLoadMapImage } from 'src/@realtime/hooks';
import { useEffect } from 'react';
import { SPRITE_URL_PREFIX, SPRITES, SpriteOperationType } from './sprites';

/**
 * Queries features rendered on the map at a specific point and filters them by layer ID.
 *
 * This function allows you to get a list of features that are rendered at a given screen point
 * (e.g., where the user clicks or hovers), and filters them by a specified layer ID. It can be
 * used for interactions like identifying features in a specific layer, such as on-click or hover events.
 *
 * @param {mapboxgl.Map} target - The Mapbox GL JS map instance on which to query rendered features.
 * @param {mapboxgl.Point} point - The screen point (in pixels) at which to query features.
 * @param {string} layerId - The ID of the layer to filter features by.
 *
 * @returns {mapboxgl.MapboxGeoJSONFeature[]} - An array of Mapbox GeoJSON features that belong
 * to the specified layer and are rendered at the given point. If no matching features are found,
 * an empty array is returned.
 */
export const queryMapFeaturesById = (
  target: mapboxgl.Map,
  point: mapboxgl.Point,
  layerId: string
): mapboxgl.MapboxGeoJSONFeature[] => {
  // Query features rendered at the specified point on the map
  const features = target.queryRenderedFeatures(point);

  // If features are found, filter them by the given layer ID
  if (features) {
    return features.filter(({ layer }) => layer.id === layerId);
  }

  // Return an empty array if no features are found
  return [];
};

/**
 * Queries features rendered on the map at a specific point and filters them by layer IDs.
 *
 * This function allows you to get a list of features that are rendered at a given screen point
 * (e.g., where the user clicks or hovers), and filters them by a specified layer ID. It can be
 * used for interactions like identifying features in a specific layer, such as on-click or hover events.
 *
 * @param {mapboxgl.Map} target - The Mapbox GL JS map instance on which to query rendered features.
 * @param {mapboxgl.Point} point - The screen point (in pixels) at which to query features.
 * @param {string[]} layerId - The ID of the layer to filter features by.
 *
 * @returns {mapboxgl.MapboxGeoJSONFeature[]} - An array of Mapbox GeoJSON features that belong
 * to the specified layer and are rendered at the given point. If no matching features are found,
 * an empty array is returned.
 */
export const queryMapFeaturesByIds = (
  target: mapboxgl.Map,
  point: mapboxgl.Point,
  layerId: string[]
): mapboxgl.MapboxGeoJSONFeature[] => {
  // Query features rendered at the specified point on the map
  const features = target.queryRenderedFeatures(point);

  // If features are found, filter them by the given layer ID
  if (features) {
    return features.filter(({ layer }) => layerId.includes(layer.id));
  }

  // Return an empty array if no features are found
  return [];
};

export const loadMapSprites = (trackTheme = 'default', onLoaded?: () => void): void => {
  const loadMapImages = useLoadMapImage(onLoaded);

  useEffect(() => {
    const spriteList = [];

    Object.entries(SPRITES).flatMap(([sKey, sValue]) => {
      spriteList.push({
        url: `${SPRITE_URL_PREFIX}/shadow/${sValue}`,
        key: `shadow-${sKey}`,
      });
      Object.entries(SpriteOperationType).flatMap(([oKey, oValue]) => {
        spriteList.push({
          url: `${SPRITE_URL_PREFIX}/${trackTheme}/${oValue}-${sValue}`,
          key: `${oValue}-${sKey}`,
        });
      });
    });
    void loadMapImages(spriteList);
  }, [loadMapImages]);
};

/**
 * Parses the URL hash to extract map parameters.
 *
 * The URL hash is expected to be in the format `#zoom/latitude/longitude/bearing/pitch`.
 * The function splits the hash by `/` and converts each part to a number.
 * If the hash contains at least three valid numbers (zoom, latitude, and longitude),
 * it returns an object with the following properties:
 * - `zoom`: The zoom level of the map.
 * - `latitude`: The latitude coordinate.
 * - `longitude`: The longitude coordinate.
 * - `bearing`: The bearing of the map (optional, defaults to 0).
 * - `pitch`: The pitch of the map (optional, defaults to 0).
 *
 * @returns An object with map parameters if the hash is valid, otherwise `null`.
 */
export const parseUrlHash = () => {
  const hash = location.hash.replace('#', '');
  const parts = hash.split('/').map(Number);

  if (parts.length >= 3 && parts.every(v => !isNaN(v))) {
    return {
      zoom: parts[0],
      latitude: parts[1],
      longitude: parts[2],
      bearing: parts[3] || 0,
      pitch: parts[4] || 0,
    };
  }

  return null;
};
