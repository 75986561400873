import { produce } from 'immer';
import {
  PLAYBACK_BUFFER_SIZE,
  PLAYBACK_HISTORY_MILLISECONDS,
  PLAYBACK_TICK_INTERVAL_MS,
  PLAYBACK_LAST_FEW_SECONDS_OF_DATA,
  PLAYBACK_MAX_HISTORY,
} from 'src/@realtime/constants';
import {
  PlaybackAction,
  PlaybackActionType,
  PlaybackMode,
  PlaybackState,
  PlaybackStatus,
} from './types';
import { initialPlaybackState } from './context';

export const playbackReducer = produce((draft: PlaybackState, action: PlaybackAction) => {
  switch (action.type) {
    case PlaybackActionType.ADD_TIMESTAMP: {
      const newTimestamp = action.payload;

      if (draft.timestamps.length === 0) {
        draft.minTimestamp = newTimestamp + PLAYBACK_BUFFER_SIZE;
        draft.maxTimestamp = newTimestamp + PLAYBACK_HISTORY_MILLISECONDS;
        draft.timestamps.push(newTimestamp);
        draft.maxPlayableTimestamp = newTimestamp;
      } else {
        const lastTimestamp = draft.timestamps[draft.timestamps.length - 1];

        // Skip if it's a duplicate or out of order
        if (newTimestamp > lastTimestamp) {
          draft.timestamps.push(newTimestamp);
          draft.maxPlayableTimestamp = newTimestamp - PLAYBACK_BUFFER_SIZE;

          if (newTimestamp + PLAYBACK_BUFFER_SIZE * 2 > draft.maxTimestamp) {
            draft.maxTimestamp = newTimestamp + PLAYBACK_BUFFER_SIZE;
          }
        }
      }
      break;
    }

    case PlaybackActionType.RESTART: {
      Object.assign(draft, initialPlaybackState);
      break;
    }

    case PlaybackActionType.SET_PLAYBACK_STATUS:
      draft.playbackStatus = action.payload;
      break;

    case PlaybackActionType.SET_CURRENT_TIMESTAMP:
      draft.currentTimestamp = action.payload;
      break;

    case PlaybackActionType.SET_PLAYBACK_MODE:
      draft.playbackMode = action.payload;
      break;

    case PlaybackActionType.SET_IS_LIVE:
      draft.isLive = action.payload;
      if (draft.isLive) {
        draft.playbackSpeed = 1;
      }
      break;

    case PlaybackActionType.ADVANCE_TIMESTAMP: {
      const { playbackSpeed } = draft;
      // (playbackSpeed !== 1 ? 4 : 1)
      const incrementAmount = PLAYBACK_TICK_INTERVAL_MS * playbackSpeed;
      const nextTimestamp =
        draft.currentTimestamp + incrementAmount / (playbackSpeed === 1 ? 1 : 4);

      if (nextTimestamp > draft.maxTimestamp) {
        if (draft.playbackMode === PlaybackMode.Historical) {
          draft.playbackStatus = PlaybackStatus.Paused;
          draft.playbackSpeed = 1;
        } else {
          draft.playbackStatus = PlaybackStatus.Buffering;
        }
      } else {
        draft.currentTimestamp = Math.ceil(nextTimestamp / 1000) * 1000;
      }

      if (draft.isLive) {
        draft.currentTimestamp = Math.min(
          Math.ceil(nextTimestamp / 1000) * 1000,
          draft.maxPlayableTimestamp
        ); // Prevent jumping past real-time
      }

      // We can't resume at the very last time as it will go into buffering, so take 10 seconds ago.
      if (draft.timestamps.length < PLAYBACK_LAST_FEW_SECONDS_OF_DATA) {
        return;
      }

      const lastFewSecondsAvailable =
        draft.timestamps[draft.timestamps.length - PLAYBACK_LAST_FEW_SECONDS_OF_DATA];
      if (
        draft.playbackSpeed > 1 &&
        (nextTimestamp > lastFewSecondsAvailable || nextTimestamp > draft.maxPlayableTimestamp)
      ) {
        draft.isLive = true;
        draft.playbackSpeed = 1;
        draft.currentTimestamp = Math.min(lastFewSecondsAvailable, draft.maxPlayableTimestamp);
      }
      break;
    }

    case PlaybackActionType.PRUNE_OLD_TIMESTAMPS: {
      const cutoffTimestamp = action.payload;
      draft.timestamps = draft.timestamps.filter(ts => ts >= cutoffTimestamp);
      draft.minTimestamp = draft.timestamps.length > 0 ? draft.timestamps[0] : cutoffTimestamp;
      break;
    }

    case PlaybackActionType.SET_PLAYBACK_SPEED:
      draft.playbackSpeed = action.payload;
      break;

    case PlaybackActionType.SET_MIN_TIMESTAMP:
      draft.minTimestamp = action.payload;
      break;

    case PlaybackActionType.SET_MAX_TIMESTAMP:
      draft.maxTimestamp = action.payload;
      break;

    case PlaybackActionType.SET_MAX_PLAYABLE_TIMESTAMP:
      draft.maxPlayableTimestamp = action.payload;
      break;

    case PlaybackActionType.SET_REALTIME_HISTORY:
      // Dont allow users to request less than the minimum history
      if (action.payload < PLAYBACK_MAX_HISTORY) {
        draft.realtimeHistory = PLAYBACK_MAX_HISTORY;
      } else {
        draft.realtimeHistory = action.payload;
      }

      break;

    default:
      break;
  }
});
