import React, { useEffect, useMemo, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import { RealtimeLayerIds, RealtimeMapEventIds } from 'src/@realtime/constants';
import { activeNmtLayerStyle, inActiveNmtLayerStyle, NmtTooltipContainer } from './styles';
import { useBuildActiveNmt } from './hooks';
import { MapEventTypes } from 'src/@realtime/contexts/map';
import { queryMapFeaturesByIds } from 'src/@realtime/utils';
import { useAddMapEventFunction, useMapConfigsWrapper } from 'src/@realtime/hooks';
import { NmtLayerProperties } from './types';
import { RealtimeMapTag } from 'src/@realtime/components';
import { useNmtContext, usePlaybackContext } from 'src/@realtime/contexts';
import { useMap } from 'react-map-gl';
import { NmtLevelTags } from './components';
import { BLANK_STRING_PLACEHOLDER, UNIT_DECIBEL } from 'src/constants';

const NMT_ZOOM_THRESHOLD = 11.5;

export const NmtTooltip: React.FC<{
  coordinates: GeoJSON.Position;
  locationId: number;
  zoomLevel: number;
}> = ({ coordinates, locationId, zoomLevel }) => {
  if (!coordinates) {
    return null;
  }
  const {
    state: { monitors, samples },
  } = useNmtContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  const monitor = monitors.find(monitor => monitor.locationId === locationId);
  const sample = samples[locationId]?.find(
    ({ time }) => new Date(time).getTime() === currentTimestamp
  );

  return (
    <RealtimeMapTag longitude={coordinates[0]} latitude={coordinates[1]} anchor={'top-right'}>
      <NmtTooltipContainer>
        <span className="deviceName" style={{ fontWeight: 'bold', lineHeight: '1' }}>
          {monitor?.deviceName}
        </span>
        <span className="locationDescription">{monitor?.locationDescription === monitor?.deviceName ? '' : monitor?.locationDescription}</span>
        {zoomLevel < NMT_ZOOM_THRESHOLD &&
          (sample ? (
            <span className="noiseLevel">
              {sample.value.toFixed(2)} {UNIT_DECIBEL}
            </span>
          ) : (
            <span className="noiseLevel">{BLANK_STRING_PLACEHOLDER}</span>
          ))}
      </NmtTooltipContainer>
    </RealtimeMapTag>
  );
};

export const NmtLayer: React.FC = () => {
  const { current: map } = useMap();
  const { zoom } = useMapConfigsWrapper();
  const [zoomLevel, setZoomLevel] = useState<number>(zoom);
  const [tooltipData, setTooltipData] = useState<{
    coordinates: GeoJSON.Position;
    locationId: number;
  } | null>(null);

  const { geoJSON, monitorList } = useBuildActiveNmt();
  const nmtHoverFunction = useMemo(
    () => ({
      id: RealtimeMapEventIds.nmtHover,
      type: MapEventTypes.HOVER,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesByIds(target, point, [RealtimeLayerIds.nmtActive, RealtimeLayerIds.nmtInActive]);
        if (features[0]) {
          const feature = (features[0] as unknown) as GeoJSON.Feature<GeoJSON.Geometry, NmtLayerProperties>;

          let coordinates: GeoJSON.Position;
          if (feature.geometry.type === 'Point') {
            coordinates = feature.geometry.coordinates;
          } else if (feature.geometry.type === 'Polygon') {
            // Use the center of the square for the tooltip
            const ring = feature.geometry.coordinates[0];
            const [lngMin, latMin] = ring[0];
            const [lngMax, latMax] = ring[2];
            coordinates = [
              (lngMin + lngMax) / 2,
              (latMin + latMax) / 2,
            ];
          } else {
            // Fallback (shouldn’t happen)
            coordinates = [0, 0];
          }

          setTooltipData({
            coordinates,
            locationId: feature.properties.locationId,
          });
        } else {
          setTooltipData(null);
        }
      },
    }),
    []
  );

  useAddMapEventFunction(nmtHoverFunction);

  useEffect(() => {
    if (!map) {
      return;
    }
    const handleZoom = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoom', handleZoom);

    return () => {
      map.off('zoom', handleZoom);
    };
  }, [map]);


  useEffect(() => {
    if (!map) {
      return;
    }

    if (!map.hasImage('nmt-square')) {
      const canvas = document.createElement('canvas');
      canvas.width = canvas.height = 32;
      const ctx = canvas.getContext('2d')!;
      ctx.fillStyle = '#5a6872';
      ctx.fillRect(0, 0, 32, 32);
      const imageData = ctx.getImageData(0, 0, 32, 32);

      map.addImage('nmt-square', {
        width: 32,
        height: 32,
        data: imageData.data,
      });
    }
  }, [map]);

  return (
    <>
      <Source id={RealtimeLayerIds.nmtActive} type="geojson" data={geoJSON}>
        <Layer {...activeNmtLayerStyle} filter={['==', ['get', 'isInActive'], true]} />
        <Layer {...inActiveNmtLayerStyle('#5a6872', (zoomLevel / 45) * 1)} />
        {/* TODO: the size calculation from the ZoomLevel  */}
        {/* TODO: Use a single symbol layer to switch between Circle and Sqaure */}
        {/* TODO: isMaintenceMode : '#6666FF' ? '#5a6872' - preparding for Maintenance Mode, I couldn't find either Maintenance or InActive NMT colours in the sass variables */}
      </Source>
      {NmtTooltip && <NmtTooltip {...tooltipData} zoomLevel={zoomLevel} />}
      {zoomLevel > NMT_ZOOM_THRESHOLD && (
        <NmtLevelTags monitorList={monitorList} selectedTagId={tooltipData?.locationId || null} />
      )}
    </>
  );
};
