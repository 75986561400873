import { MapLayerMouseEvent, ViewState } from 'react-map-gl';
import { ClickHandlerConfig, Cursor, MapProps, MapState } from '../map.types';
import { PayloadAction } from 'src/app/interfaces';

export const MAP_ACTIONS = {
  SET_VIEW_STATE: 'SET_VIEW_STATE',
  SET_MAP_BACKGROUND: 'SET_MAP_BACKGROUND',
  SET_CURSOR_OVERRIDE: 'SET_CURSOR_OVERRIDE',
  SET_MAP_PROPS: 'SET_MAP_PROPS',
  ADD_CLICK_HANDLER: 'ADD_CLICK_HANDLER',
  REMOVE_CLICK_HANDLER: 'REMOVE_CLICK_HANDLER',
  CLEAR_CLICK_HANDLERS: 'CLEAR_CLICK_HANDLERS',
  ADD_INTERACTIVE_LAYERS: 'ADD_INTERACTIVE_LAYERS',
  REMOVE_INTERACTIVE_LAYERS: 'REMOVE_INTERACTIVE_LAYERS',
  OVERRIDE_MAP_DRAG: 'OVERRIDE_MAP_DRAG',
  RESET_MAP_DRAG: 'RESET_MAP_DRAG',
};

export const mapReducer = (state: MapState, action: PayloadAction<unknown>) => {
  switch (action.type) {
    case MAP_ACTIONS.SET_VIEW_STATE: {
      const { payload } = action as PayloadAction<Partial<ViewState>>;
      return {
        ...state,
        mapProps: { ...state.mapProps, ...payload },
      };
    }
    case MAP_ACTIONS.SET_MAP_BACKGROUND: {
      const { payload } = action as PayloadAction<string>;
      return {
        ...state,
        mapStyle: payload,
      };
    }
    case MAP_ACTIONS.SET_CURSOR_OVERRIDE: {
      const { payload } = action as PayloadAction<Cursor | null>;
      return {
        ...state,
        cursorOverride: payload,
      };
    }

    case MAP_ACTIONS.SET_MAP_PROPS: {
      const { payload } = action as PayloadAction<MapProps>;
      let prop: keyof Partial<MapProps>;
      for (prop as string in payload) {
        // @ts-ignore
        state.mapProps[prop] = payload[prop];
      }
      return state;
    }
    case MAP_ACTIONS.ADD_CLICK_HANDLER: {
      const { payload } = action as PayloadAction<ClickHandlerConfig>;
      const currentOnClickFunctions = [...state.onClickFunctions];
      const existingClickFunctionIndex = currentOnClickFunctions.findIndex(
        item => item.id === payload.id
      );
      if (existingClickFunctionIndex === -1) {
        currentOnClickFunctions.push(payload);
      } else {
        currentOnClickFunctions[existingClickFunctionIndex] = payload;
      }
      const onClick = (event: MapLayerMouseEvent) => {
        for (const handler of currentOnClickFunctions) {
          handler.function(event);
        }
      };
      return {
        ...state,
        onClickFunctions: currentOnClickFunctions,
        mapProps: {
          ...state.mapProps,
          onClick,
        },
      };
    }
    case MAP_ACTIONS.ADD_INTERACTIVE_LAYERS: {
      const { payload } = action as PayloadAction<string[]>;
      return {
        ...state,
        mapProps: {
          ...state.mapProps,
          interactiveLayerIds: (state.mapProps.interactiveLayerIds ?? [])
            .filter(id => !payload.includes(id))
            .concat(payload),
        },
      };
    }
    case MAP_ACTIONS.REMOVE_INTERACTIVE_LAYERS: {
      const { payload } = action as PayloadAction<string[]>;
      return {
        ...state,
        mapProps: {
          ...state.mapProps,
          interactiveLayerIds: (state.mapProps.interactiveLayerIds ?? []).filter(
            id => !payload?.includes(id)
          ),
        },
      };
    }
    case MAP_ACTIONS.REMOVE_CLICK_HANDLER: {
      const { payload } = action as PayloadAction<string>;
      const onClickFunctions = state.onClickFunctions.filter(handler => handler.id !== payload);
      const onClick = (event: MapLayerMouseEvent) => {
        for (const func of onClickFunctions) {
          func(event);
        }
      };
      return {
        ...state,
        onClickFunctions,
        mapProps: {
          ...state.mapProps,
          onClick,
        },
      };
    }
    case MAP_ACTIONS.CLEAR_CLICK_HANDLERS: {
      const onClickFunctions = [];
      const onClick = (event: MapLayerMouseEvent) => {
        for (const func of onClickFunctions) {
          func(event);
        }
      };
      return {
        ...state,
        onClickFunctions,
        mapProps: {
          ...state.mapProps,
          onClick,
        },
      };
    }
    default:
      return state;
  }
};
