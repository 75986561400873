import { OperationType } from 'src/@realtime/contexts';

export interface GetOperationsByTimeRangeVariables {
  startTime: string; // Assuming DateTimeOffset is represented as a string
  endTime: string; // Assuming DateTimeOffset is represented as a string
  timeRangeMode?: TimeRangeQueryMode;
  first?: number;
}

export interface OperationPoint {
  alt: number;
  lat: number;
  lon: number;
  t: number; // seconds since the operation start time
}

export interface OperationItem {
  acid: string;
  beaconCode: number;
  remoteAirportId: string;
  id: string;
  aircraftType: string;
  aircraftCategory: string;
  tailNumber: string;
  airportId: string;
  operationType: OperationType;
  startTime: string; // Assuming DateTimeOffset is represented as a string
  endTime: string; // Assuming DateTimeOffset is represented as a string
  points: OperationPoint[];
}

export interface GetOperationsByTimeRangeResponse {
  operationsByTimeRange: {
    totalCount: number;
    items: OperationItem[];
  };
}

export enum TimeRangeQueryMode {
  InFlight = 'InFlight',
  PrimaryTime = 'PrimaryTime',
  StartTime = 'StartTime',
  EndTime = 'EndTime',
}
