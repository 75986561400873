import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { TimeDisplay } from '../timeDisplay';
import {
  ControlButtonsWrapper,
  ControlButtonContainer,
  LiveButton,
  DropdownWrapper,
} from './styles';
import { JumpButton } from './jumpButton';
import { useStartPlayback } from 'src/@realtime/hooks';
import { useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';
import { IDropdownItem } from '@ems/client-design-system';
import { Dropdown } from '@ems/client-design-system';
import { usePlaybackContext, PlaybackActionType, PlaybackStatus } from 'src/@realtime/contexts';
import { stopPlayback } from 'src/@realtime/utils/timeProgressionManager';

const MemoizedDropdown = React.memo(Dropdown);

export const ControlButtons: React.FC = () => {
  const goLive = useStartPlayback();

  const {
    state: { currentTimestamp, isLive, playbackSpeed, playbackStatus },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      realtime: {
        buttons: { live: liveButtonString },
      },
    },
  } = languageSelectors.getLanguage() as {
    screens: {
      realtime: {
        buttons: {
          live: string;
        };
      };
    };
  };

  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
  } = configSelectors.getConfig();

  // Memoize available speeds to prevent unnecessary recalculations
  const availableSpeeds = useMemo(
    () =>
      ['1x', '2x', '6x', '15x', '30x', '60x', '90x'].map(speed => ({
        key: speed.replace('x', ''),
        label: speed,
      })),
    []
  );

  const [selectedSpeed, setSelectedSpeed] = useState<IDropdownItem>(availableSpeeds[0]);

  // Sync selectedSpeed with playbackSpeed from context
  useEffect(() => {
    const matchedSpeed = availableSpeeds.find(s => s.key === String(playbackSpeed));
    if (matchedSpeed && matchedSpeed.label !== selectedSpeed.label) {
      setSelectedSpeed(matchedSpeed);
    }
  }, [playbackSpeed, availableSpeeds, selectedSpeed]);

  // Memoize selected speed object to prevent unnecessary re-renders
  const selectedSpeedMemo = useMemo(() => selectedSpeed, [selectedSpeed]);

  const handleSpeedChange = useCallback(
    (value: IDropdownItem) => {
      // Force stop the current playback
      stopPlayback();

      const selected = availableSpeeds.find(speed => speed.label === value.label);
      if (selected) {
        setSelectedSpeed(selected);

        // Update speed in context
        playbackDispatch({
          type: PlaybackActionType.SET_PLAYBACK_SPEED,
          payload: parseInt(selected.key, 10),
        });

        // If we were playing, trigger a re-play to use the new speed
        if (playbackStatus === PlaybackStatus.Playing) {
          console.log('Restarting playback with new speed');
          // Brief pause
          playbackDispatch({
            type: PlaybackActionType.SET_PLAYBACK_STATUS,
            payload: PlaybackStatus.Paused,
          });

          // Then restart
          setTimeout(() => {
            playbackDispatch({
              type: PlaybackActionType.SET_PLAYBACK_STATUS,
              payload: PlaybackStatus.Playing,
            });
          }, 50);
        }
      }
    },
    [availableSpeeds, playbackDispatch, playbackStatus]
  );

  const handleGoLive = useCallback(() => {
    stopPlayback();
    goLive(true);
  }, [goLive]);

  return (
    <ControlButtonsWrapper>
      <TimeDisplay currentTimestamp={currentTimestamp} twelveHourFormat={twelveHourFormat} />
      <ControlButtonContainer>
        <JumpButton direction={'backward'} disabled={false} currentTimestamp={currentTimestamp} />
        <JumpButton direction={'forward'} disabled={isLive} currentTimestamp={currentTimestamp} />
        <DropdownWrapper isDoubleDigit={selectedSpeedMemo.label.length > 2}>
          <MemoizedDropdown
            disabled={isLive}
            placeholderValue={selectedSpeedMemo.label}
            searchItems={availableSpeeds}
            isNullable={false}
            selectedItem={isLive ? availableSpeeds[0] : selectedSpeedMemo}
            updateSelection={handleSpeedChange}
            width={40}
          />
        </DropdownWrapper>
        <LiveButton disabled={isLive} className="live" size="large" onClick={handleGoLive}>
          {liveButtonString.toUpperCase()}
        </LiveButton>
      </ControlButtonContainer>
    </ControlButtonsWrapper>
  );
};
