import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useMap, Source, Layer } from 'react-map-gl';
import mapboxgl from "mapbox-gl";

// Components
import { ToolTipSelectionLayer } from '../flightSelectionLayer';

// Contexts
import { MapEventTypes } from 'src/@realtime/contexts/map';

// Hooks
import { useBuildFlightTails } from './hooks';
import { useAddMapEventFunction } from 'src/@realtime/hooks';

// Functions
import { calculateVelocityKn, queryMapFeaturesByIds } from 'src/@realtime/utils';
import { flattenGeoJSONLineFeature } from 'src/@realtime/utils/multiLineStrings';
import { nearestPointOnLine, point } from '@turf/turf';

// Constants
import { selectedTailStyle, defaultTailStyle, selectedHiddenTailStyle } from './styles';
import { RealtimeLayerIds, AltitudeSource } from 'src/@realtime/constants';

// Types
import { TailLineStringProperties } from 'src/@realtime/types/flight';
import { findNearestPoint } from 'src/@realtime/utils';
import { FlightPosition, useFlightDataContext } from 'src/@realtime/contexts';

const useMatchFlightPosition = () => {
  const {
    state: { positions },
  } = useFlightDataContext();

  return useCallback(
    (
      trackId: string,
      event: mapboxgl.MapMouseEvent,
      lineFeature: GeoJSON.Feature<GeoJSON.LineString, TailLineStringProperties>
    ) => {
      const trackPositions: FlightPosition[] = positions[trackId];
      if (!trackPositions) {
        return;
      }

      // Convert the mouse event point to a Turf point.
      const { lngLat } = event;
      const turfPoint = point([lngLat.lng, lngLat.lat]);

      // Find the nearest point on the given line feature.
      const nearestPointFeature = nearestPointOnLine(lineFeature, turfPoint);
      const [nearestLng, nearestLat] = nearestPointFeature.geometry.coordinates;

      // Search through available positions until the closest one to the nearestPoint is found.
      let closestFlightPosition: FlightPosition | undefined;
      let minDistance = Number.MAX_VALUE;

      for (const position of trackPositions) {
        // Calculate the Euclidean distance.
        const distance = Math.sqrt(
          Math.pow(position.longitude - nearestLng, 2) + Math.pow(position.latitude - nearestLat, 2)
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestFlightPosition = position;
        }
      }

      return closestFlightPosition;
    },
    [positions]
  );
};

export const FlightTailLayer: React.FC = () => {
  const flightTailsData = useBuildFlightTails();
  if (!flightTailsData) {
    return null;
  }

  const [flightTails, selectedFlightTails] = flightTailsData;
  const [tooltipData, setTooltipData] = useState<{
    coordinates: GeoJSON.Position;
    altitude?: number;
    altitudeSource: AltitudeSource;
    trackId: string;
    speed?: number;
  } | null>(null);

  const findPoint = useMatchFlightPosition();

  const hoverAircraftFunction = useMemo(
    () => ({
      id: 'my-map-aircraft-hover',
      type: MapEventTypes.HOVER,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesByIds(target, point, [
          RealtimeLayerIds.flightLayer,
          RealtimeLayerIds.selectedHiddenTail,
        ]); // Add a layer for aircraft
        if (features[0]) {
          if (features[0].properties) {
            if (features[0].geometry.type === 'Point') {
              const feature = (features[0] as unknown) as GeoJSON.Feature<
                GeoJSON.Point,
                {
                  altitude: number;
                  trackId: string;
                }
              >;
              setTooltipData({
                coordinates: feature.geometry.coordinates,
                altitude: null,
                altitudeSource: AltitudeSource.dynamic,
                trackId: feature.properties.trackId,
              });
            } else if (features[0].geometry.type === 'LineString' || (features[0].geometry.type === 'MultiLineString')) {
              const feature = (features[0] as unknown) as GeoJSON.Feature<
                GeoJSON.LineString | GeoJSON.MultiLineString,  // Either LineString's or at close zoom levels gemetries split and they're MultiLineString's.
                TailLineStringProperties
              >;

              // Converts MultiLineString to LineString.
              const flatFeature = flattenGeoJSONLineFeature(feature);

              const [pointPosition, prevPosition] = findNearestPoint(event, flatFeature);

              // Find the previous and current flight positions to work out speed
              const flightPosition: FlightPosition = findPoint(flatFeature.properties.trackId, event, flatFeature) || { time: 0, latitude: 0, longitude: 0, altitude: 0, heading: 0 };
              const prevFlightPosition: FlightPosition = prevPosition
                ? findPoint(
                  flatFeature.properties.trackId,
                  { lngLat: new mapboxgl.LngLat(prevPosition[0], prevPosition[1]) } as unknown as mapboxgl.MapMouseEvent,
                  flatFeature
                ) || { time: 0, latitude: 0, longitude: 0, altitude: 0, heading: 0 }
                : { time: 0, latitude: 0, longitude: 0, altitude: 0, heading: 0 };

              const point1 = prevFlightPosition && prevPosition ? {
                latitude: prevPosition[0],
                longitude: prevPosition[1],
                altitude: prevFlightPosition.altitude,
                time: prevFlightPosition.time,
              } : null;

              const point2 = flightPosition && pointPosition ? {
                latitude: pointPosition[0],
                longitude: pointPosition[1],
                altitude: flightPosition.altitude,
                time: flightPosition.time,
              } : null;

              // Call calculateVelocityKn with properly formatted objects
              let speed: number | null = null;
              if (point1 && point2) {
                speed = calculateVelocityKn(point1, point2);
              }

              setTooltipData({
                coordinates: pointPosition,
                altitude: flightPosition.altitude,
                altitudeSource: AltitudeSource.provided,
                trackId: flatFeature.properties.trackId,
                speed,
              });
            }
          }
        } else {
          setTooltipData(null);
        }
      },
    }),
    [findPoint]
  );

  const { current: map } = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    map.getCanvas().style.cursor = tooltipData ? 'pointer' : 'default';

    return () => {
      map.getCanvas().style.cursor = 'default'; // Reset cursor on cleanup
    };
  }, [map, tooltipData]); // Dependencies

  useAddMapEventFunction(hoverAircraftFunction);

  return (
    <>
      <Source id={RealtimeLayerIds.defaultTail} type="geojson" data={flightTails}>
        <Layer {...defaultTailStyle} />
      </Source>
      <Source id={RealtimeLayerIds.selectedTail} type="geojson" data={selectedFlightTails}>
        <Layer {...selectedTailStyle} />
        <Layer {...selectedHiddenTailStyle} />
      </Source>
      {tooltipData && <ToolTipSelectionLayer {...tooltipData} />}
    </>
  );
};
