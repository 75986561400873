import React from 'react';

export const IconRealtime = ({ size = 24 }: { size?: number }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2_700)">
      <path
        d="M20.7478 5.48202C19.2755 3.50602 17.1775 2.08669 14.7956 1.4552C12.4137 0.823712 9.88813 1.01726 7.63025 2.00432C5.37237 2.99138 3.51518 4.71381 2.36115 6.89108C1.20712 9.06836 0.824229 11.5722 1.27484 13.9949C1.72546 16.4175 2.98302 18.6163 4.84274 20.233C6.70245 21.8497 9.05475 22.7892 11.5165 22.8984C13.9783 23.0076 16.4046 22.2801 18.4001 20.8344C20.3957 19.3887 21.843 17.3099 22.5064 14.9367"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.832 5.67312L13.5801 6.64089L13.832 5.67312ZM9.45193 6.00259L9.84625 6.92156L9.84625 6.92156L9.45193 6.00259ZM6.23102 8.93465L7.11093 9.4098L7.11093 9.4098L6.23102 8.93465ZM5.56698 13.1969L6.5495 13.0107L5.56698 13.1969ZM7.74797 16.9398L8.39707 16.1791L8.39707 16.1791L7.74797 16.9398ZM19.5072 14.0359C19.6585 13.5047 19.3505 12.9515 18.8193 12.8003C18.2882 12.649 17.7349 12.957 17.5837 13.4881L19.5072 14.0359ZM18.267 7.48458C17.2297 6.11818 15.7545 5.1401 14.0838 4.70535L13.5801 6.64089C14.8216 6.96394 15.9112 7.68905 16.674 8.69385L18.267 7.48458ZM14.0838 4.70535C12.4134 4.27066 10.6422 4.40368 9.0576 5.08362L9.84625 6.92156C11.0221 6.41703 12.3385 6.3178 13.5801 6.64089L14.0838 4.70535ZM9.0576 5.08362C7.47273 5.76368 6.16507 6.95217 5.35112 8.4595L7.11093 9.4098C7.70785 8.30439 8.67072 7.42598 9.84625 6.92156L9.0576 5.08362ZM5.35112 8.4595C4.53695 9.96721 4.26608 11.703 4.58447 13.3831L6.5495 13.0107C6.31698 11.7837 6.51421 10.5148 7.11093 9.4098L5.35112 8.4595ZM4.58447 13.3831C4.90281 15.0631 5.79062 16.5842 7.09887 17.7005L8.39707 16.1791C7.43171 15.3553 6.78205 14.238 6.5495 13.0107L4.58447 13.3831ZM7.09887 17.7005C8.40678 18.8165 10.0583 19.4629 11.784 19.5381L11.871 17.54C10.5871 17.4841 9.36279 17.0031 8.39707 16.1791L7.09887 17.7005ZM11.784 19.5381C13.5097 19.6132 15.2124 19.1129 16.6149 18.1156L15.4559 16.4857C14.4186 17.2232 13.1551 17.5959 11.871 17.54L11.784 19.5381ZM16.6149 18.1156C18.0177 17.1181 19.0387 15.681 19.5072 14.0359L17.5837 13.4881C17.2412 14.6909 16.4927 15.7484 15.4559 16.4857L16.6149 18.1156Z"
        fill="#fff"
      />
      <path
        d="M21.3121 6.2657C21.7601 5.9427 21.8614 5.31769 21.5384 4.86971C21.2154 4.42172 20.5904 4.3204 20.1424 4.6434L21.3121 6.2657ZM11.9151 13.0409L21.3121 6.2657L20.1424 4.6434L10.7454 11.4186L11.9151 13.0409Z"
        fill="#fff"
      />
      <circle cx="12" cy="12" r="1.09091" fill="#fff" stroke="#fff" strokeWidth="2.18182" />
    </g>
    <defs>
      <clipPath id="clip0_2_700">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
