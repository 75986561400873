import { IIntentProps } from '@blueprintjs/core';
import { displayToast } from '@ems/client-design-system';
import { RealtimeConnectionType } from 'src/@realtime/contexts';

const REALTIME_TOAST_ID = 'realtimeConnectionErrorToast';
const TOAST_TIMEOUT = 50000;

export const connectionToast = ({
  type,
  message,
  intent,
}: {
  type: RealtimeConnectionType;
  message: string;
  intent: IIntentProps['intent'];
}): void => {
  displayToast({
    key: `${REALTIME_TOAST_ID}_${type}`,
    message,
    intent,
    timeout: TOAST_TIMEOUT,
  });
};
