import { useEffect, useState } from 'react';
import { useConfigSelectors } from 'src/app/reducers';

export const useGetTimeZone = () => {
  const [timeZone, setTimeZone] = useState<string | undefined>(undefined);
  const configSelectors = useConfigSelectors();

  useEffect(() => {
    const {
      globals: { timeZone },
    } = configSelectors.getConfig();
    setTimeZone(timeZone);
  }, [configSelectors]);

  return timeZone;
};
