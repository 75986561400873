import React /* useEffect, useState  */ from 'react';
import {
  /* useNmtContext */
  useFlightDataContext,
  usePlaybackContext,
  useRealtimeConnectionContext,
} from 'src/@realtime/contexts';
import { playbackTimeFormatShort, getCurrentTime } from 'src/utils';

export const DebugPanel = () => {
  const {
    state: { flightInfo /* positions */ },
  } = useFlightDataContext();

  /*   const {
    state: { monitors, samples },
  } = useNmtContext(); */

  const { flightConnection, noiseConnection } = useRealtimeConnectionContext();
  const {
    state: {
      timestamps,
      playbackStatus,
      currentTimestamp,
      minTimestamp,
      maxTimestamp,
      maxPlayableTimestamp,
      playbackSpeed,
    },
  } = usePlaybackContext();

  /*   const [showDetails, setShowDetails] = useState(false);

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDetails(e.target.checked);
  }; */

  /*   useEffect(() => {
    if (!showDetails) {
      return;
    }

    monitors.forEach(monitor => {
      const { locationId, locationDescription } = monitor;
      const monitorSamples = samples[locationId];
      const count = monitorSamples?.length || 0;
      console.log(`Monitor "${locationDescription}" has ${count} samples`);
    });

    Object.entries(positions).forEach(([trackId, positionList]) => {
      const flight = flightInfo[trackId];
      const acId = flight?.acId || trackId;
      const count = positionList.length;
      console.log(`Flight "${acId}" has ${count} positions`);
    });
  }, [samples, flightInfo, positions]); */

  return (
    <div
      style={{
        backgroundColor: 'white',
        float: 'left',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        padding: '10px',
      }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>Number of flights loaded: {Object.keys(flightInfo).length}</p>
        <p>Playback Status: {playbackStatus}</p>
        <p>
          Current Timestamp:
          {currentTimestamp !== 0 &&
            playbackTimeFormatShort(getCurrentTime(currentTimestamp), true)}
        </p>
        <p>
          Min Timestamp:
          {minTimestamp !== 0 && playbackTimeFormatShort(getCurrentTime(minTimestamp), true)}
        </p>
        <p>
          Max Timestamp:
          {maxTimestamp !== 0 && playbackTimeFormatShort(getCurrentTime(maxTimestamp), true)}
        </p>
        <p>
          Max Playable Time:
          {maxPlayableTimestamp !== 0 &&
            playbackTimeFormatShort(getCurrentTime(maxPlayableTimestamp), true)}
        </p>
        <p>
          First Timestamp:
          {timestamps.length > 0
            ? playbackTimeFormatShort(getCurrentTime(timestamps[0]), true)
            : 'N/A'}
        </p>
        <p>
          Last Timestamp:
          {timestamps.length > 0
            ? playbackTimeFormatShort(getCurrentTime(timestamps[timestamps.length - 1]), true)
            : 'N/A'}
        </p>
        <p>
          Time range:
          {timestamps.length > 0
            ? `${((timestamps[timestamps.length - 1] - timestamps[0]) / 1000).toFixed(2)} seconds`
            : 'N/A'}
        </p>
        <p>PlaybackSpeed: {playbackSpeed}</p>
        {flightConnection && (
          <button
            onClick={() => flightConnection?.stop()}
            disabled={flightConnection?.state !== 'Connected'}>
            Force disconnect flights
          </button>
        )}
        {noiseConnection && (
          <button
            onClick={() => noiseConnection?.stop()}
            disabled={noiseConnection?.state !== 'Connected'}>
            Force disconnect noise
          </button>
        )}

        {/* <label
          style={{ display: 'inline-flex', alignItems: 'center', fontSize: '16px', gap: '6px' }}>
          <input type="checkbox" checked={showDetails} onChange={handleToggle} />
          <span>Show Data Stream in Console Output</span>
        </label> */}
      </div>
    </div>
  );
};
