import React from 'react';
// selectors
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { useConfigSelectors, useRolesSelectors } from 'src/app/reducers';
// components
import { Link } from 'react-router-dom';
import { BlockHeader, DescriptionList, Card, Icons, Tooltip } from '@ems/client-design-system';
// functions
import { IRouteItem } from 'src/app/props';
import { capitalizeObjectKeys, getDeployedProductId, getOperationTheme } from 'src/utils';
import { routes } from 'src/app/routes';
import { canAccessRoute } from 'src/app/components/CanAccessRoute';
//constants 
import { OPERATIONS } from 'src/constants';

export const OperationDetailsContainer = ({ operation }) => {
  let items: any = [];
  let operationLink: any = null;
  const rolesSelectors = useRolesSelectors();
  const configSelectors = useConfigSelectors();
  const availableRoutes = configSelectors.getAvailableRoutes();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const currentOperationsTheme = getOperationTheme(selectedTrackTheme);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      operationDetails: { title },
      lists: { operationTypes, aircraftCategories },
    },
    fields: {
      infringements: {
        acid,
        airportId,
        remoteAirportId,
        runwayName,
        aircraftCategory,
        aircraftType,
        airline,
        operationType,
        pathName,
        tailNumber,
        beaconCode,
        operatorCategory,
        noiseClass,
      },
    },
  } = languageSelectors.getLanguage();

  const {
    operationDetails: { noiseClass: noiseClassSwitch },
  } = configSelectors.getConfig();

  const hasData: boolean = operation && !!Object.keys(operation).length;
  const convertedTranslationTooltips = capitalizeObjectKeys({
    ...operationTypes,
    ...aircraftCategories,
  });

  if (hasData) {
    const operationTheme = currentOperationsTheme[operation.operationType.toLowerCase()];
    const operationName = operation.operationType.toLowerCase();
    const operationRoute = routes.find((route: IRouteItem) => route.route === 'operations');
    const hasOperationRoutePermission =
      operationRoute &&
      canAccessRoute(operationRoute.permissions, rolesSelectors) &&
      availableRoutes.includes(operationRoute.route);

    if (hasOperationRoutePermission) {
      operationLink = (
        <Link
          className="correlatedCollapse__header-link"
          to={{
            pathname: `/${getDeployedProductId()}/${OPERATIONS}/${operation.id}`,
          }}>
          {operation.id}
          <Icons iconName="ic-external" />
        </Link>
      );
    }

    items = [
      {
        title: operationType,
        description: (
          <div className={'operation-details_opType'}>
            <Tooltip content={convertedTranslationTooltips[operation.operationType]}>
              <Icons
                className={`ad-${operationName}`}
                iconName={`ic-ad-${operationName}`}
                size={28}
                style={{
                  fill: operationTheme,
                  color: operationTheme,
                }}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        title: acid,
        description: operation.acid,
      },
      {
        title: tailNumber,
        description: operation.tailNumber,
      },
      {
        title: beaconCode,
        description: operation.beacon,
      },
      {
        title: aircraftCategory,
        description: (
          <Tooltip content={convertedTranslationTooltips[operation.aircraftCategory]}>
            <Icons iconName={`ic-ac-${operation.aircraftCategory.toLowerCase()}`} size={20} />
          </Tooltip>
        ),
      },
      {
        title: aircraftType,
        description: operation.aircraftType,
      },
      {
        title: operatorCategory,
        description: operation.operatorCategory,
      },
      {
        title: airline,
        description: operation.airline,
      },
      {
        title: airportId,
        description: operation.airportId,
      },
      {
        title: remoteAirportId,
        description: operation.remoteAirportId,
      },
      {
        title: runwayName,
        description: operation.runwayName,
      },
      {
        title: pathName,
        description: operation.pathname,
      }
    ];

    if (noiseClassSwitch !== 'None') {
      switch (noiseClassSwitch) {
        case 'azb':
          if (
            typeof operation.aircraft !== 'undefined' &&
            operation.aircraft.hasOwnProperty('azbNoiseClassification') &&
            operation.aircraft.azbNoiseClassification
          ) {
            items.push({
              title: noiseClass,
              description: operation.aircraft.azbNoiseClassification,
            });
          }
        case 'icao':
          if (
            typeof operation.aircraft !== 'undefined' &&
            operation.aircraft.hasOwnProperty('icaoNoiseClassification') &&
            operation.aircraft.icaoNoiseClassification
          ) {
            items.push({
              title: noiseClass,
              description: operation.aircraft.icaoNoiseClassification,
            });
          }
        default:
          items.push({
            title: noiseClass,
            description: '—',
          });
      }
    }
  } else {
    items = [
      {
        title: operationType,
        description: null,
      },
      {
        title: acid,
        description: null,
      },
      {
        title: tailNumber,
        description: null,
      },
      {
        title: beaconCode,
        description: null,
      },
      {
        title: aircraftCategory,
        description: null,
      },
      {
        title: aircraftType,
        description: null,
      },
      {
        title: operatorCategory,
        description: null,
      },
      {
        title: airline,
        description: null,
      },
      {
        title: airportId,
        description: null,
      },
      {
        title: remoteAirportId,
        description: null,
      },
      {
        title: runwayName,
        description: null,
      },
      {
        title: pathName,
        description: null,
      },
    ];
  }

  return (
    <div className="block">
      {hasData ? (
        <BlockHeader title={title} cta={operationLink} />
      ) : (
        <BlockHeader title={title} loading />
      )}
      <Card>
        <DescriptionList items={items} loading={!hasData} />
      </Card>
    </div>
  );
};
