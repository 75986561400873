import { useMemo, useRef } from 'react';
import { point, featureCollection } from '@turf/turf';
import { NoiseSample, useNmtContext, usePlaybackContext } from 'src/@realtime/contexts';
import { NoiseMonitor } from 'src/@realtime/queries';
import { NmtFeature } from './types';

export const useBuildActiveNmt = () => {
  const {
    state: { monitors, hasLoaded, samples },
  } = useNmtContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  const activeMonitorsRef = useRef<NoiseMonitor[]>([]);

  useMemo(() => {
    activeMonitorsRef.current = monitors.length
      ? monitors.filter(monitor => monitor.isActive)
      : [];
  }, [monitors]);

  return useMemo(() => {
    if (!hasLoaded || !activeMonitorsRef.current.length || !samples) {
      return { geoJSON: null, monitorList: [] };
    }

    const featureList: {
      locationId: number;
      position: { latitude: number; longitude: number };
      matchingSample: NoiseSample | null;
    }[] = [];

    const features: NmtFeature[] = activeMonitorsRef.current
      .map(monitor => {
        const { locationId, position } = monitor;
        if (!position || !position.longitude || !position.latitude) {
          return null;
        }

        const monitorSamples = samples[locationId];
        const matchingSample = monitorSamples?.find(({ time }) => new Date(time).getTime() === currentTimestamp) || null;

        featureList.push({ locationId, position, matchingSample });

        return point(
          [position.longitude, position.latitude],
          {
            locationId,
            noiseLevel: matchingSample?.value || 0,
            isInActive: matchingSample != null,
          }
        );
      })
      .filter(Boolean) as NmtFeature[];

    return {
      geoJSON: featureCollection<NmtFeature['geometry'], NmtFeature['properties']>(features),
      monitorList: featureList,
    };
  }, [hasLoaded, samples, currentTimestamp]);
};