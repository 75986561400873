import { useCallback } from 'react';
import { useUrlParam } from 'src/@realtime/hooks';
import { DateTime } from 'luxon';
import { useConfigSelectors } from 'src/app/reducers';

export const useGetUrlDate: DateTime = () => {
  const { getUrlParam } = useUrlParam();
  const configSelectors = useConfigSelectors();
  const {
    globals: { timeZone },
  } = configSelectors.getConfig();
  return useCallback(() => {
    const dateParam = getUrlParam('date');

    if (dateParam) {
      return DateTime.fromFormat(dateParam, "yyyy-MM-dd'T'HH:mm:ss", {
        zone: timeZone,
      });
    }
  }, [getUrlParam]);
};
