import { MapStyles } from 'src/@realtime/constants';

export type MapStyle = keyof typeof MapStyles;

export enum MapActionType {
  SET_MAP_LOADED = 'SET_MAP_LOADED',
  SET_IS_SPRITES_LOADED = 'SET_IS_SPRITES_LOADED',
  UPDATE_STYLE = 'UPDATE_STYLE',
  TOGGLE_SETTINGS = 'TOGGLE_SETTINGS',
  CLOSE_SETTINGS = 'CLOSE_SETTINGS',
  SAVE_SETTINGS = 'SAVE_SETTINGS',
  RESET_TO_DEFAULT = 'RESET_TO_DEFAULT',
  ADD_CLICK_EVENT = 'ADD_CLICK_EVENT',
  REMOVE_CLICK_EVENT = 'REMOVE_CLICK_EVENT',
  TOGGLE_MEASURING = 'TOGGLE_MEASURING',
}

export enum MapEventTypes {
  CLICK = 'click',
  HOVER = 'hover',
}
export interface MapEventConfig {
  id: string;
  type: MapEventTypes;
  event: (event: mapboxgl.MapLayerMouseEvent) => void;
}

export type MapStyleType = typeof MapStyles[keyof typeof MapStyles];
export interface MapState {
  isMapLoaded: boolean;
  isSpritesLoaded: boolean;
  selectedMapStyle: MapStyleType;
  isMapSettingsOpen: boolean;
  mapEvents: MapEventConfig[];
  stateToUpdate: {
    selectedMapStyle: MapStyleType;
  } | null;
  isMeasuring: boolean;
}

// Action types
export type MapAction =
  | {
      type: MapActionType.SET_MAP_LOADED;
    }
  | {
      type: MapActionType.SET_IS_SPRITES_LOADED;
      payload: boolean;
    }
  | {
      type: MapActionType.UPDATE_STYLE;
      payload: MapStyleType;
    }
  | {
      type: MapActionType.TOGGLE_SETTINGS;
    }
  | {
      type: MapActionType.CLOSE_SETTINGS;
    }
  | {
      type: MapActionType.SAVE_SETTINGS;
    }
  | {
      type: MapActionType.RESET_TO_DEFAULT;
    }
  | {
      type: MapActionType.ADD_CLICK_EVENT;
      payload: MapEventConfig;
    }
  | {
      type: MapActionType.REMOVE_CLICK_EVENT;
      payload: string;
    }
  | {
      type: MapActionType.TOGGLE_MEASURING;
    };
