import { Feature, LineString, MultiLineString } from 'geojson';
import { TailLineStringProperties } from 'src/@realtime/types/flight';

export const flattenGeoJSONLineFeature = <T extends TailLineStringProperties>(
  feature: Feature<LineString | MultiLineString, T>
): Feature<LineString, T> => {
  if (feature.geometry.type === 'MultiLineString') {
    return {
      ...feature,
      geometry: {
        type: 'LineString',
        coordinates: feature.geometry.coordinates.flat()
      }
    } as Feature<LineString, T>;
  }

  return feature as Feature<LineString, T>;
};