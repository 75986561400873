import { useEffect } from 'react';
import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';
import { PlaybackActionType, PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';
import { startPlayback, stopPlayback } from '../../utils/timeProgressionManager';

export const useTimeProgression = () => {
  const {
    state: { playbackStatus, playbackSpeed, isLive },
    dispatch,
  } = usePlaybackContext();

  useEffect(() => {
    if (playbackStatus === PlaybackStatus.Playing) {
      // Set the interval based on playback speed and whether it's live
      // If playback speed is not 1, we divide the interval by 4 to speed up the playback
      // This allows for smooth 'skipping' (see the reducer action)
      const intervalMs = isLive
        ? PLAYBACK_TICK_INTERVAL_MS
        : PLAYBACK_TICK_INTERVAL_MS / (playbackSpeed !== 1 ? 4 : 1);

      // Advance once immediately
      dispatch({ type: PlaybackActionType.ADVANCE_TIMESTAMP });

      // Play continiously on timer
      startPlayback(intervalMs, () => {
        dispatch({ type: PlaybackActionType.ADVANCE_TIMESTAMP });
      });
    } else {
      stopPlayback();
    }

    return () => stopPlayback();
  }, [playbackStatus, playbackSpeed, isLive, dispatch]);
};
