// SignalR constants
export const STREAM_METHOD_NAMES = {
  GET_TRACK_POINTS: 'GetTrackPoints',
  GET_NOISE_SAMPLES: 'GetNoiseSamples',
};

export const STREAM_HUB_NAMES = {
  REAL_TIME_FLIGHT: 'realtimeflight',
  REAL_TIME_NOISE: 'realtimenoise',
};

// Playback constants
export const SIGNALR_CACHE_THRESHOLD_MINUTES = 20;
export const PLAYBACK_HISTORY_MINUTES = 60; // Playback history to keep in minutes
export const PLAYBACK_HISTORY_SECONDS = PLAYBACK_HISTORY_MINUTES * 60;
export const PLAYBACK_HISTORY_MILLISECONDS = PLAYBACK_HISTORY_SECONDS * 1000;
export const PLAYBACK_TICK_INTERVAL_MS = 1000; // Tick speed
export const PLAYBACK_MAX_HISTORY = 120; // History to load from endpoint in seconds
export const PLAYBACK_BUFFER_SIZE = PLAYBACK_TICK_INTERVAL_MS * 20; // Buffer size in ms
export const PLAYBACK_LAST_FEW_SECONDS_OF_DATA = 10;
export const LOADING_BATCH_TIME = 2000; // Time to wait before loading next batch of data
export const RESTART_WAIT_TIME_AFTER_USER_RETURNS = 600000; // 10 mins, Max Time we wait before resetting the playback after user returns from navigating away
export const BACK_TO_THE_FUTURE_AFTER_USER_RETURNS = 30000; // 30 seconds
// Map constants
export const MapStyles = {
  Basic: {
    url: 'mapbox://styles/ems-webapps/ck0q8r5892qw91dodc1zl82uh',
    name: 'Basic',
    img: '/map/mapstyle_basic.png',
  },
  Street: {
    url: 'mapbox://styles/james-ems/cm6p03404001501so09feaimv',
    name: 'Street',
    img: '/map/mapstyle_street.png',
  },
  Satellite: {
    url: 'mapbox://styles/james-ems/cm6p1jnld00h101sqaevx4ywt',
    name: 'Satellite',
    img: '/map/mapstyle_satellite.png',
  },
};

// This list MUST be in order of preference, shadow layer uses last layer in order to apply itself
export enum RealtimeLayerIds {
  // Tooltip
  toolTipAircraft = 'tooltip-aircraft',
  // Flights
  flightLayer = 'flight-layer',
  flightShadowLayer = 'flight-shadow-layer',
  selectedAircraftCircle = 'selected-aircraft-pulsating-circle',
  // Tails
  defaultTail = 'default-tail',
  selectedTail = 'selected-tail',
  selectedHiddenTail = 'selected-hidden-tail', // This is used to help with event register
  // Noise
  nmtActive = 'nmt-active',
  nmtInActive = "nmt-inactive-squares",
}

export enum RealtimeMapEventIds {
  nmtHover = 'nmt-hover',
}

export enum AltitudeSource {
  provided,
  dynamic,
}

export const SHADOW_ICON_OFFSETS: Record<number, [number, number]> = {
  0: [4.44, 4.44],
  15: [5.444, 3.143],
  30: [6.071, 1.627],
  45: [6.284, 0.0],
  60: [6.071, -1.627],
  75: [5.444, -3.143],
  90: [4.44, -4.44],
  105: [3.143, -5.444],
  120: [1.627, -6.071],
  135: [0.0, -6.284],
  150: [-1.627, -6.071],
  165: [-3.143, -5.444],
  180: [-4.44, -4.44],
  195: [-5.444, -3.143],
  210: [-6.071, -1.627],
  225: [-6.284, 0.0],
  240: [-6.071, 1.627],
  255: [-5.444, 3.143],
  270: [-4.44, 4.44],
  285: [-3.143, 5.444],
  300: [-1.627, 6.071],
  315: [0.0, 6.284],
  330: [1.627, 6.071],
  345: [3.143, 5.444],
  360: [4.44, 4.44],
};
