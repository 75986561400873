import { NoiseMonitor } from 'src/@realtime/queries';
import { StreamedNoiseSample } from 'src/@realtime/types';

export interface NoiseSample extends Omit<StreamedNoiseSample, 'error' | 'metric'> {
  time: string;
  value: number;
}
export interface NmtState {
  monitors: NoiseMonitor[];
  hasLoaded: boolean;
  samples: Record<string, NoiseSample[]>;
  lastNoisePruneTime: number;
}

export enum NmtActionType {
  ADD_MONITORS = 'ADD_MONITORS',
  REMOVE_MONITORS = 'REMOVE_MONITORS',
  ADD_NOISE_SAMPLE = 'ADD_NOISE_SAMPLE',
  ADD_NOISE_SAMPLES = 'ADD_NOISE_SAMPLES',
  ADD_HISTORICAL_SAMPLES = 'ADD_HISTORICAL_SAMPLES',
  PRUNE_OLD_NOISE_DATA = 'PRUNE_OLD_NOISE_DATA',
  RESET_SAMPLE_DATA = 'RESET_SAMPLE_DATA',
}

// Action types
export type NmtAction =
  | {
      type: NmtActionType.ADD_MONITORS;
      payload: NoiseMonitor[];
    }
  | {
      type: NmtActionType.REMOVE_MONITORS;
      payload: NoiseMonitor[];
    }
  | {
      type: NmtActionType.ADD_NOISE_SAMPLE;
      payload: StreamedNoiseSample;
    }
  | {
      type: NmtActionType.ADD_NOISE_SAMPLES;
      payload: StreamedNoiseSample[];
    }
  | {
      type: NmtActionType.ADD_HISTORICAL_SAMPLES;
      payload: Record<string, NoiseSample[]>;
    }
  | {
      type: NmtActionType.PRUNE_OLD_NOISE_DATA;
      payload: number;
    }
  | {
      type: NmtActionType.RESET_SAMPLE_DATA;
    };
