import gql from 'graphql-tag';

export const GET_OPERATIONS_BY_TIME_RANGE = gql`
  query getOperationsByTimeRange(
    $startTime: DateTimeOffset!
    $endTime: DateTimeOffset!
    $timeRangeMode: TimeRangeQueryMode
    $first: Int
  ) {
    operationsByTimeRange(
      startTime: $startTime
      endTime: $endTime
      timeRangeMode: $timeRangeMode
      first: $first
    ) {
      totalCount
      items {
        acid
        beaconCode
        remoteAirportId
        id
        aircraftType
        aircraftCategory
        tailNumber
        airportId
        operationType
        startTime
        endTime
        points {
          alt
          lat
          lon
          t
        }
      }
    }
  }
`;
