import React from "react";
import { DateTime } from "luxon";
import { useUrlParam } from "src/@realtime/hooks/url/useUrlParam";
import { useSetPlaybackMode, useStartPlayback } from "src/@realtime/hooks";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { ControlButton } from './styles';
import { useConfigSelectors } from 'src/app/reducers';
import { PlaybackActionType, usePlaybackContext } from 'src/@realtime/contexts';
import { PLAYBACK_HISTORY_SECONDS, SIGNALR_CACHE_THRESHOLD_MINUTES } from 'src/@realtime/constants';

interface JumpButtonProps {
  direction: 'forward' | 'backward';
  disabled?: boolean;
  currentTimestamp: number;
}

export const JumpButton: React.FC<JumpButtonProps> = ({ direction, disabled, currentTimestamp }) => {
  const { getUrlParam } = useUrlParam();
  const updatePlayback = useSetPlaybackMode();
  const goLive = useStartPlayback();

  const { dispatch: playbackDispatch } = usePlaybackContext();

  const configSelectors = useConfigSelectors();
  const {
    globals: { timeZone },
  } = configSelectors.getConfig();

  const handleJump = () => {
    let dateStr = getUrlParam('date');

    // If user clicks a Jump button without first choosing hitorical date then instead of the date query string we use the current timestamp.
    if (!dateStr) {
      dateStr = DateTime.fromMillis(currentTimestamp).setZone(timeZone).toISO();
    }

    const date = DateTime.fromISO(dateStr, { zone: timeZone });
    if (date.isValid) {
      const jumpAmount = direction === 'forward' ? 1 : -1; // Set +1 for forward, -1 for backward
      const newDate = date.plus({ hours: jumpAmount }); // Adjust by the jump amount

      // User Jumps into the future or types a future date in the URL.
      const now = DateTime.now().setZone(timeZone);
      const diffInMinutes = now.diff(newDate, 'minutes').minutes;

      if (newDate > now) {
        goLive(true);
        return;
      }

      // Trigger playback, which updates the url param to the new date +/- 1 hour.
      updatePlayback(newDate);

      // When we have to switch back to SignalR ensure time gets updated!
      if (Math.abs(diffInMinutes) < SIGNALR_CACHE_THRESHOLD_MINUTES) {
        playbackDispatch({
          type: PlaybackActionType.SET_MIN_TIMESTAMP,
          payload: newDate.toMillis(),
        });
        playbackDispatch({
          type: PlaybackActionType.SET_MAX_TIMESTAMP,
          payload: newDate.plus({ seconds: PLAYBACK_HISTORY_SECONDS }).toMillis(),
        });
        playbackDispatch({
          type: PlaybackActionType.SET_MAX_PLAYABLE_TIMESTAMP,
          payload: newDate.plus({ seconds: PLAYBACK_HISTORY_SECONDS }).toMillis(),
        });
        playbackDispatch({
          type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
          payload: newDate.toMillis(),
        });
      }
    } else {
      console.error("Invalid date in URL:", dateStr);
    }
  };

  return (
    <ControlButton onClick={handleJump} disabled={disabled} size="small">
      {direction === "backward" ? <FaChevronLeft /> : <FaChevronRight />}
    </ControlButton>
  );
};

export default JumpButton;
