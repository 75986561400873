import React, { useEffect } from 'react';

// Hooks
import { useQuery } from '@apollo/react-hooks';
import { PlaybackActionType, PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';
import {
  useAddFlight,
  useAddFlightPath,
  useAddNoiseMonitorSamples,
  useSetTimeRange,
} from './hooks';
import { useGetTimeZone, useGetUrlDate } from 'src/@realtime/hooks';

// Utils
import { buildTrackData } from './utils';
import { interpolateFlightPath } from 'src/@realtime/utils';
import { DateTime } from 'luxon';

// Queries
import {
  GET_NOISE_MONITOR_SAMPLES_BY_TIME,
  GET_OPERATIONS_BY_TIME_RANGE,
  GetNoiseMonitorSamplesByTimeReponse,
  GetNoiseMonitorSamplesByTimeVariables,
  GetOperationsByTimeRangeResponse,
  GetOperationsByTimeRangeVariables,
  TimeRangeQueryMode,
} from 'src/@realtime/queries';

// Constants
import { NOISE_MONITOR_METRICS } from 'src/constants';
import { PLAYBACK_HISTORY_SECONDS } from 'src/@realtime/constants';

const Connections: React.FC = () => {
  const addFlight = useAddFlight();
  const addPositions = useAddFlightPath();
  const addNoiseSamples = useAddNoiseMonitorSamples();
  const timeZone = useGetTimeZone();
  const setCorrectTimeRange = useSetTimeRange();
  const getUrlDate = useGetUrlDate();

  const {
    state: { minTimestamp, maxTimestamp },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  const [timeRange, setTimeRange] = React.useState<{
    startTime: string;
    endTime: string;
  }>({
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    if (timeZone) {
      const urlDate: DateTime = getUrlDate();
      // Check if the URL date is valid and within the min/max timestamps
      // Prevents issue with interpolated dispatch actions blocking correct historical date
      if (urlDate.ts && urlDate.ts !== minTimestamp) {
        console.log(`Found wrong date in dispatch. url: ${urlDate.ts}, dispatch: ${minTimestamp}`);
        setCorrectTimeRange(urlDate);
        setTimeRange({
          startTime: DateTime.fromMillis(urlDate.ts, { zone: timeZone }).toISO(),
          endTime: DateTime.fromMillis(urlDate.ts, { zone: timeZone })
            .plus({ seconds: PLAYBACK_HISTORY_SECONDS })
            .toISO(),
        });
      } else {
        setTimeRange({
          startTime: DateTime.fromMillis(minTimestamp, { zone: timeZone }).toISO(),
          endTime: DateTime.fromMillis(maxTimestamp, { zone: timeZone }).toISO(),
        });
      }
    }
  }, [minTimestamp, maxTimestamp, timeZone]);

  useQuery<GetOperationsByTimeRangeResponse, GetOperationsByTimeRangeVariables>(
    GET_OPERATIONS_BY_TIME_RANGE,
    {
      variables: {
        startTime: timeRange.startTime,
        endTime: timeRange.endTime,
        timeRangeMode: TimeRangeQueryMode.InFlight,
        first: 10000,
      },
      skip: !timeRange.startTime || !timeRange.endTime,
      onCompleted: data => {
        if (!data) {
          return;
        }

        data.operationsByTimeRange.items.forEach(item => {
          const { id } = item;
          addFlight(item);
          const trackData = buildTrackData(item, minTimestamp, maxTimestamp);
          addPositions(interpolateFlightPath(trackData || []), String(id));
        });

        playbackDispatch({
          type: PlaybackActionType.SET_PLAYBACK_STATUS,
          payload: PlaybackStatus.Playing,
        });
      },
    }
  );

  useQuery<GetNoiseMonitorSamplesByTimeReponse, GetNoiseMonitorSamplesByTimeVariables>(
    GET_NOISE_MONITOR_SAMPLES_BY_TIME,
    {
      variables: {
        startTime: timeRange.startTime,
        endTime: timeRange.endTime,
        filter: {
          sampleTypes: [NOISE_MONITOR_METRICS.LEQ],
        },
      },
      skip: !timeRange.startTime || !timeRange.endTime,
      onCompleted: data => {
        addNoiseSamples(data);
      },
    }
  );

  return null;
};

export const HistoricalConnections = React.memo(Connections);
