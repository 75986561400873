import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const useUrlParam = () => {
  const location = useLocation();
  const history = useHistory();

  const updateUrlParam = useCallback(
    (paramName: string, paramValue: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(paramName, paramValue);

      history.replace({
        pathname: location.pathname,
        search: decodeURIComponent(searchParams.toString()),
        hash: location.hash,
      });
    },
    [location, history]
  );

  const getUrlParam = useCallback(
    (paramName: string): string | null => {
      const searchParams = new URLSearchParams(location.search);
      const value = searchParams.get(paramName);
      return value ? decodeURIComponent(value) : null;
    },
    [location, history]
  );

  const removeUrlParam = useCallback(
    (paramName: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(paramName);

      history.replace({
        pathname: location.pathname,
        search: decodeURIComponent(searchParams.toString()),
        hash: location.hash,
      });
    },
    [location, history]
  );

  return { updateUrlParam, getUrlParam, removeUrlParam };
};
