import React from 'react';

import styled from 'styled-components/macro';
import { Popup, PopupProps } from 'react-map-gl';
import { BLANK_STRING_PLACEHOLDER } from 'src/constants';
import { NoiseSample } from 'src/@realtime/contexts';

export const NmtPopup = styled(Popup)<PopupProps>`
  .mapboxgl-popup-content {
    box-shadow: 0 2px 16px 0 rgba(39, 45, 51, 0.11);
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1;
    color: #000;
    padding: 2px;
    border-radius: 2px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    line-height: 1;
    font-variant-numeric: tabular-nums;
    pointer-events: none; /* Makes the tooltip allow mouse events to flightpaths underneath */
  }

  &.selected .mapboxgl-popup-content {
    background-color: rgba(255, 255, 255, 1);
  }

  .mapboxgl-popup-tip {
    display: none; /* Hides the default arrow */
  }

  .mapboxgl-popup-close-button {
    display: none; /* Hides the default arrow */
  }
`;

export const NmtLevelTags: React.FC<{
  monitorList: {
    locationId: number;
    position: { latitude: number; longitude: number };
    matchingSample: NoiseSample;
  }[];
  selectedTagId: number;
}> = ({ monitorList, selectedTagId }) => (
  <>
    {monitorList.map(({ locationId, matchingSample, position }) => {
      const offset = matchingSample && matchingSample.value > 70 ? [24, 0] : [16, 0];
      return (
        <NmtPopup
          longitude={position.longitude}
          latitude={position.latitude}
          offset={offset}
          key={locationId}
          anchor="left"
          className={selectedTagId === locationId ? 'selected' : ''}>
          {matchingSample ? (
            <span className="noiseLevel">{matchingSample.value.toFixed(1)}</span>
          ) : (
            <span className="noiseLevel">{BLANK_STRING_PLACEHOLDER}</span>
          )}
        </NmtPopup>
      );
    })}
  </>
);
