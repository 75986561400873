import { useCallback, useRef, useMemo, useEffect } from 'react';

import {
  FlightDataActionType,
  FlightPosition,
  StreamedFlightPoint,
  useFlightDataContext,
} from 'src/@realtime/contexts/flight';
import { PlaybackActionType, usePlaybackContext } from 'src/@realtime/contexts/playback';
import { useConfigSelectors } from 'src/app/reducers';
import { getOperationTypeColor } from 'src/utils';
import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';
import { useAddFlightPath } from 'src/@realtime/components/historicalConnections/hooks';
import { interpolateFlightPath } from 'src/@realtime/utils';

export const useLoadBatch = () => {
  const lastPositionStore = useRef<Record<string, FlightPosition>>({});
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = useMemo(() => configSelectors.getTheme('operations'), [
    configSelectors,
  ]);
  const addPositions = useAddFlightPath();
  const { dispatch: flightDispatch } = useFlightDataContext();
  const {
    state: { realtimeHistory },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  // When realtimeHistory changes, reset the last position store
  useEffect(() => {
    lastPositionStore.current = {};
  }, [realtimeHistory]);

  return useCallback(
    (points: StreamedFlightPoint[], trackId: string) => {
      if (points.length === 0) {
        return;
      }

      const firstPoint = points[0];
      flightDispatch({
        type: FlightDataActionType.ADD_FLIGHT,
        payload: {
          ...firstPoint,
          operationColor: getOperationTypeColor(selectedTrackTheme, firstPoint.operationType),
        },
      });

      const positions = points.map(({ time, latitude, longitude, altitude }) => ({
        time:
          Math.round(new Date(time).getTime() / PLAYBACK_TICK_INTERVAL_MS) *
          PLAYBACK_TICK_INTERVAL_MS,
        latitude,
        longitude,
        altitude,
        heading: 0,
      }));

      const lastPosition = lastPositionStore.current[trackId];
      if (lastPosition) {
        positions.unshift(lastPosition);
      }

      playbackDispatch({
        type: PlaybackActionType.ADD_TIMESTAMP,
        payload: positions[0].time,
      });

      addPositions(interpolateFlightPath(positions), trackId);
      lastPositionStore.current[trackId] = positions[positions.length - 1];
    },
    [flightDispatch, playbackDispatch, addPositions, selectedTrackTheme]
  );
};
