import React, { useCallback, useState } from 'react';
import RcSlider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { StyledSliderTooltip, sliderStyles, RcSliderWrapper } from './styles';
import { PlaybackActionType, PlaybackMode, usePlaybackContext } from 'src/@realtime/contexts';
import { playbackTimeFormatShort, getCurrentTime } from 'src/utils/dateTimeConverters';
import { PLAYBACK_TICK_INTERVAL_MS, LOADING_BATCH_TIME } from 'src/@realtime/constants';
import { useConfigSelectors } from 'src/app/reducers';

export const Slider: React.FC = () => {
  const {
    state: {
      currentTimestamp,
      minTimestamp,
      maxTimestamp,
      maxPlayableTimestamp,
      playbackMode,
      isLive,
    },
    dispatch,
  } = usePlaybackContext();

  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
  } = configSelectors.getConfig();

  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const handleMouseEnter = () => setTooltipVisible(true);
  const handleMouseLeave = () => setTooltipVisible(false);

  const handleOnChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return;
    }

    // Enable or disable live mode when user moves the slider
    if (playbackMode === PlaybackMode.Realtime) {
      dispatch({
        type: PlaybackActionType.SET_IS_LIVE,
        payload: value >= maxPlayableTimestamp,
      });
    } else if (playbackMode === PlaybackMode.Historical && isLive) {
      dispatch({
        type: PlaybackActionType.SET_IS_LIVE,
        payload: false,
      });
    }

    // Prevent user from seeking past the max playable timestamp
    if (value > maxPlayableTimestamp - LOADING_BATCH_TIME) {
      return;
    }
    dispatch({
      type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
      payload: value,
    });
  };

  const tipHandleRender: SliderProps['handleRender'] = useCallback(
    (node, handleProps) => (
      <>
        <StyledSliderTooltip
          className={isTooltipVisible ? 'visible' : ''}
          style={{ left: node.props.style.left }}>
          {playbackTimeFormatShort(getCurrentTime(handleProps.value), twelveHourFormat)}
        </StyledSliderTooltip>
        {React.cloneElement(node, {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        })}
      </>
    ),
    [isTooltipVisible]
  );

  return (
    <RcSliderWrapper>
      <RcSlider
        min={minTimestamp}
        max={maxTimestamp}
        value={currentTimestamp}
        step={PLAYBACK_TICK_INTERVAL_MS}
        handleRender={tipHandleRender}
        styles={sliderStyles}
        onChange={handleOnChange}
      />
    </RcSliderWrapper>
  );
};
