import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

interface DropdownWrapperProps {
  isDoubleDigit: boolean;
}

export const ControlButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: bottom;
`;

export const ControlButton = styled.button<{ size: 'small' | 'large' }>`
  width: ${props => (props.size === 'large' ? '28px' : '18px')};
  height: 18px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: 1px solid ${sassVars.ui05};
  font-size: 10px;
  border-radius: 20px;
  color: ${sassVars.ui04};
  text-transform: none;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    background: ${sassVars.ui04};
    color: white;
    fill: white;
    cursor: pointer;
  }

  &:disabled {
    pointer-events: none; /* Prevents clicks */
    opacity: 0.5; /* Grey out effect */
  }

 /* Override hover when disabled */
  &:disabled:hover {
    background: transparent;
    color: ${sassVars.ui04};
    fill: ${sassVars.ui04};
    cursor: default;
  }
`;

export const LiveButton = styled(ControlButton)`
  background: ${sassVars.ui04};
  color: white;
  fill: white;
  cursor: pointer;
  border: none;

  &:disabled {
    background: ${sassVars.support01};
    cursor: default;
    opacity: 1;
  }
`;

export const ControlButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;

    &.live {
      background: ${sassVars.support01};
    }
  }

  @media (max-width: 500px) {
    margin-right: 0px;
    justify-content: flex-end;
    margin-top: -18px;
  }

  @media (max-width: 540px) {
    margin-right: 0px;
    justify-content: flex-end;
    margin-top: -10px;
  }
    
`;

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  .bp4-popover-target {
    width: 100%;
  }

  .dropdown_button {
    width: 40px !important;
    height: 18px !important;
  }

  .dropdown_button--chevron {
    display: none !important;
  }
    
  .dropdown .bp3-button {
    padding: 0 5px !important;
     border-radius: 18px;
  }

  .dropdown_wrap {
    position: relative;
    left: ${({ isDoubleDigit }) => (isDoubleDigit ? '1px' : '5px')};
    width: ${({ isDoubleDigit }) => (isDoubleDigit ? '27px' : '20px')};
  }
`;