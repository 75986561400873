import { RealtimeConnectionType, useAuthStoreContext } from 'src/@realtime/contexts';
import { useConfigSelectors } from 'src/app/reducers';
import { getDeployedProductId } from 'src/utils';

/**
 * Creates a buffer handler that batches items into a buffer and flushes them
 * either after a specified interval or manually.
 *
 * @template T - The type of items to be stored in the buffer.
 *
 * @param bufferRef - A mutable reference to the buffer object where items are stored.
 *                    The buffer is a record where keys are strings and values are arrays of type `T`.
 * @param flushCallback - A callback function that is invoked when the buffer is flushed.
 *                        It receives the current buffer as an argument.
 * @param timeoutRef - A mutable reference to the timeout object used to schedule the buffer flush.
 *                     It is set to `null` when no timeout is active.
 * @param flushInterval - The interval (in milliseconds) after which the buffer is automatically flushed.
 *
 * @returns An object containing two methods:
 *          - `addToBuffer`: Adds an item to the buffer under a specified key and schedules a flush if none is active.
 *          - `flushBuffer`: Immediately flushes the buffer, invoking the `flushCallback` and clearing the buffer.
 */
export const createBufferHandler = <T>(
  bufferRef: React.MutableRefObject<Record<string, T[]>>,
  flushCallback: (data: Record<string, T[]>) => void,
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | null>,
  flushInterval: number
) => {
  const addToBuffer = (key: string, item: T) => {
    if (!bufferRef.current[key]) {
      bufferRef.current[key] = [];
    }

    bufferRef.current[key].push(item);

    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        flushCallback(bufferRef.current);
        bufferRef.current = {}; // Clear the buffer
        timeoutRef.current = null;
      }, flushInterval);
    }
  };

  const flushBuffer = () => {
    if (Object.keys(bufferRef.current).length > 0) {
      flushCallback(bufferRef.current);
      bufferRef.current = {}; // Clear the buffer
    }
  };

  return { addToBuffer, flushBuffer };
};

/**
 * Creates a SignalR configuration object for a given connection type and stream name.
 *
 * @param {RealtimeConnectionType} type - The type of the realtime connection.
 * @param {string} streamName - The name of the stream to connect to.
 * @returns {object} The configuration object for the SignalR connection.
 * @property {string} url - The URL for the SignalR connection.
 * @property {string} token - The authentication token for the connection.
 * @property {function} onReconnected - Callback function to be called when the connection is successfully reconnected.
 * @property {function} onReconnecting - Callback function to be called when the connection is attempting to reconnect.
 * @property {function} onClose - Callback function to be called when the connection is closed or failed.
 */
export const createSignalrConfig = ({
  type,
  streamName,
  onReconnected,
  onReconnecting,
  onClose,
  onStartError,
}: {
  type: RealtimeConnectionType;
  streamName: string;
  onReconnected?: () => void;
  onReconnecting?: () => void;
  onClose?: () => void;
  onStartError?: () => void;
}): {
  url: string;
  token: string;
  onReconnected: () => void;
  onReconnecting: () => void;
  onClose: () => void;
  onStartError: () => void;
} => {
  const { token } = useAuthStoreContext();
  const {
    AviationRealtimeApi: { EndpointUrl },
  } = useConfigSelectors().getConfig();
  return {
    url: `${EndpointUrl}${getDeployedProductId()}/${streamName}`,
    token,
    onReconnected: () => {
      console.log(`${type} succesfully reconnected`);
      if (onReconnected) {
        onReconnected();
      }
    },
    onReconnecting: () => {
      console.warn(`${type} attempting to reconnect`);
      if (onReconnecting) {
        onReconnecting();
      }
    },
    onClose: () => {
      console.warn(`${type} connection failed`);
      if (onClose) {
        onClose();
      }
    },
    onStartError: () => {
      console.warn(`${type} connection start error`);
      if (onStartError) {
        onStartError();
      }
    },
  };
};
