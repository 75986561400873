import React, { useRef } from 'react';

import { NavigationControl } from 'react-map-gl';
import { useLanguageSelectors } from 'src/app/reducers';
import { useResetMapView } from 'src/@realtime/hooks';
import { MapActionType, useMapContext } from 'src/@realtime/contexts/map';
import { MapControlButton, MapSettingsModal, MeasureLayer } from 'src/@realtime/components';

interface MapControlsProps {
  showCompass?: boolean;
  resetView?: boolean;
  mapSettings?: boolean;
  measureTool?: boolean;
}

export const MapControls: React.FC<MapControlsProps> = ({
  showCompass = false,
  resetView = false,
  mapSettings = false,
  measureTool = false,
}) => {
  const measureButtonRef = useRef<HTMLButtonElement>(null);
  const { dispatch } = useMapContext();
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsString },
      labels: { backToCenter: backToCenterString, ruler: rulerString },
    },
  } = languageSelectors.getLanguage();
  const resetMap = useResetMapView();

  const handleOpenSettings = () => {
    dispatch({ type: MapActionType.TOGGLE_SETTINGS });
  };

  const toggleMeasureTool = () => {
    dispatch({ type: MapActionType.TOGGLE_MEASURING });
    // Toggle active class on measure button for styling
    measureButtonRef.current.classList.toggle('active');
    const svgElement = measureButtonRef.current.querySelector('svg');
    if (svgElement) {
      svgElement.classList.toggle('pin-icon');
      svgElement.classList.toggle('active');
    }
  };

  return (
    <div className="navigation-control" style={{ top: '62px' }}>
      <NavigationControl style={{ position: 'static' }} showCompass={showCompass} />
      {resetView && (
        <MapControlButton
          title={backToCenterString}
          onClick={resetMap}
          className="navigation-control-home"
          iconName="ic-ui-home"
          position="top-right"
        />
      )}
      {mapSettings && (
        <>
          <MapSettingsModal />
          <MapControlButton
            title={mapSettingsString}
            onClick={handleOpenSettings}
            className="mapboxgl-ctrl-settings"
            iconName="ic-ui-map-layer"
            position="top-right"
          />
        </>
      )}
      {measureTool && (
        <>
          <MapControlButton
            title={rulerString}
            onClick={toggleMeasureTool}
            className="mapboxgl-ctrl-measure"
            iconName="ic-ui-ruler"
            position="top-right"
            buttonRef={button => {
              measureButtonRef.current = button;
            }}
          />
          <MeasureLayer />
        </>
      )}
    </div>
  );
};
