import styled from 'styled-components';

export const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const PopoverContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimePickerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; // Adjust spacing between elements
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
