import React, { useMemo } from 'react';
import { AltitudeSource } from 'src/@realtime/constants';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';
import { ToolTipSelectionLayer } from '../flightSelectionLayer';
import { findPosition, smoothVelocityKnFast } from 'src/@realtime/utils/flight';

export const SelectedFlightTag = ({ trackIds }: { trackIds: string[] }): JSX.Element => {
  const {
    state: { positions },
  } = useFlightDataContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  // Memoize the positions for each trackId for the current timestamp.
  const flightTags = useMemo(
    () =>
      trackIds.reduce<JSX.Element[]>((acc, trackId) => {
        const flightPositions = positions[trackId];
        if (!flightPositions) {
          return acc;
        }

        // Find the position for the current timestamp.
        const [position, index] = findPosition(flightPositions, currentTimestamp);
        if (!position) {
          return acc;
        }

        // Find the previous and current flight positions to work out speed
        const prevPositions = flightPositions.slice(Math.max(0, index - 4), index + 1);
        const speed = smoothVelocityKnFast(prevPositions);

        acc.push(
          <ToolTipSelectionLayer
            key={trackId} // Using trackId for unique key
            coordinates={[position.longitude, position.latitude]}
            altitude={position.altitude}
            altitudeSource={AltitudeSource.provided}
            trackId={trackId}
            speed={speed}
          />
        );
        return acc;
      }, []),
    [trackIds, positions, currentTimestamp]
  );

  return <>{flightTags}</>;
};
