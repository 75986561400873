let intervalRef: NodeJS.Timeout | null = null;
let advanceCallback: (() => void) | null = null;

const startPlayback = (intervalMs: number, onAdvance: () => void) => {
  stopPlayback();
  advanceCallback = onAdvance;

  intervalRef = setInterval(() => {
    advanceCallback?.();
  }, intervalMs);
};

const stopPlayback = () => {
  if (intervalRef) {
    clearInterval(intervalRef);
    intervalRef = null;
  }
};

export { startPlayback, stopPlayback };