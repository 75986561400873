import React, { useEffect } from 'react';
import {
  PlaybackControl,
  FlightLayer,
  FlightTailLayer,
  NmtLayer,
  RealtimeConnections,
  HistoricalConnections,
  DebugPanel,
} from 'src/@realtime/components';

import { useStartPlayback, useTimeProgression } from 'src/@realtime/hooks/playback';
import { PlaybackMode, PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';
import { useUrlParam } from 'src/@realtime/hooks';

export const RealtimeLayers: React.FC = () => {
  const {
    state: { timestamps, playbackStatus, playbackMode },
    dispatch: playbackDispatch,
  } = usePlaybackContext();
  const { getUrlParam } = useUrlParam();
  const startPlayback = useStartPlayback();
  useTimeProgression();
  // Start playback if we have enough data
  useEffect(() => {
    if (timestamps.length > 5 && playbackStatus === PlaybackStatus.Buffering) {
      startPlayback(!getUrlParam('date'));
    }
  }, [timestamps, playbackDispatch]);

  return (
    <>
      {playbackMode === PlaybackMode.Realtime && <RealtimeConnections />}
      {playbackMode === PlaybackMode.Historical && <HistoricalConnections />}
      {(getUrlParam('debug') === 'true') && <DebugPanel />}
      <PlaybackControl />
      <FlightTailLayer />
      <FlightLayer />
      <NmtLayer />
    </>
  );
};
