import React, { useState } from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { DatePicker, TimePicker } from '@blueprintjs/datetime';
import { CircleButton } from '../playbackButton/styles';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { PopoverContent, TimePickerRow, ButtonWrapper } from './styles';
import { Button } from '@ems/client-design-system';
import { usePlaybackContext } from 'src/@realtime/contexts';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { getTimeZoneOffset } from 'src/utils/dateTimeConverters';
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
import { useSetPlaybackMode } from 'src/@realtime/hooks';
import { DateTime } from 'luxon';
import { useResetRealtimeData } from 'src/@realtime/components/historicalConnections/hooks';

export const useGetCurrentDate = (timeZone: string): (() => Date) => {
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  return () => {
    if (currentTimestamp === 0) {
      return DateTime.now()
        .setZone(timeZone)
        .toJSDate();
    }
    const newDate = new Date(currentTimestamp);
    newDate.setHours(newDate.getHours() + getTimeZoneOffset(timeZone));
    return newDate;
  };
};

export const CalendarButton: React.FC = () => {
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      realtime: {
        buttons: { settime: settimeButtonString },
      },
    },
  } = (languageSelectors.getLanguage() as unknown) as {
    screens: {
      realtime: {
        buttons: {
          settime: string;
        };
      };
    };
  };

  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat, timeZone },
  } = configSelectors.getConfig();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(() =>
    DateTime.now()
      .setZone(timeZone)
      .toJSDate()
  );

  const getCurrentDate = useGetCurrentDate(timeZone);
  const setPlaybackMode = useSetPlaybackMode();
  const resetData = useResetRealtimeData();

  const dispatchDateChange = () => {
    if (!selectedDate) {
      // This should not be possible as we dont allow setting selectedDate null
      console.error('Error: selected Date is null or undefined.');
      return;
    }
    setIsOpen(false);
    resetData({});

    // keepLocalTime ensures time is not changed when updating timezone
    const dateObj = DateTime.fromJSDate(selectedDate)
      .setZone(timeZone, { keepLocalTime: true })
      .set({ second: 0 });
    setPlaybackMode(dateObj);
  };

  const handleDateChange = (date: Date) => {
    if (!date) {
      return;
    }

    // If the Time when applied to today is in the future — clamp to now.
    const now = new Date();
    const newDate = new Date(date);
    if (now.toDateString() === newDate.toDateString()) {
      if (newDate.getHours() > now.getHours() ||
        (newDate.getHours() === now.getHours() && newDate.getMinutes() > now.getMinutes())
      ) {
        newDate.setHours(now.getHours(), now.getMinutes(), 0, 0);
      }
    }

    setSelectedDate(newDate);
  };

  const handleTimeChange = (time: Date) => {
    if (!selectedDate) {
      return;
    }

    const now = new Date();
    const selected = new Date(selectedDate);

    // Reconstruct a new date with selected hour/minute to force an update
    const newDate = new Date(selected);
    newDate.setHours(time.getHours(), time.getMinutes(), 0, 0);

    // If the new time is in the future, clamp to now
    if (newDate > now) {
      const clamped = new Date(selected);
      clamped.setHours(now.getHours(), now.getMinutes(), 0, 0);
      setSelectedDate(clamped);
      return;
    }

    setSelectedDate(newDate);
  };

  const togglePopover = () => {
    if (!isOpen) {
      const date = getCurrentDate();
      setSelectedDate(date);
    }
    setIsOpen(prev => !prev);
  };

  return (
    <Popover2
      isOpen={isOpen}
      position={Position.TOP}
      interactionKind={PopoverInteractionKind.CLICK}
      autoFocus
      onClose={() => setIsOpen(false)}
      content={
        <PopoverContent>
          <DatePicker onChange={handleDateChange} maxDate={new Date()} value={selectedDate} />
          <TimePickerRow>
            <TimePicker
              onChange={handleTimeChange}
              showArrowButtons={false}
              value={selectedDate}
              useAmPm={twelveHourFormat ? true : false}
              precision="minute"
            />
            <ButtonWrapper>
              <Button style="primary" className="page-tools_button" onClick={dispatchDateChange}>
                {settimeButtonString}
              </Button>
            </ButtonWrapper>
          </TimePickerRow>
        </PopoverContent>
      }>
      <CircleButton onClick={togglePopover}>
        <FaRegCalendarAlt />
      </CircleButton>
    </Popover2>
  );
};
