import React, { FC, useState, useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import { StyledMap, MapControl, GeocoderPin, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
import { NoisePopup } from 'src/components/NoisePopup';
import { LocationPopup, AMSLPopup, NMTPopup } from 'src/components';
// functions
import {
  useMapSettings,
  useRerunFunctionOnMapBackgroundChange,
  useRerunHookOnMapBackgroundChange,
} from 'src/app/functions/mapSettings';
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  useDatesDataForMap,
  fetchTrackTiles,
} from 'src/app/functions/map';
import {
  useDataForMap,
  useMapLayer,
  useMapTracksFilter,
} from 'src/app/functions/infringementsOnMap';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  flyTo,
  useHoverOnMapElement,
  useMapHover,
  useMapClick,
  useMapSelection,
  useGeometryRequiredByMap,
  dateTimeInQuery,
  addCustomTileSource,
  mapboxStyleInfringementPaint,
  useHaveTilesLoaded,
  useMapRuler,
} from 'src/utils';
import { useStaticDbDisplay } from 'src/utils/playback';
import { useDataSelectors } from '../reducers';
import { IMapProps, IRuleData } from 'src/@infringements/interfaces';
import { useInfringementRulesSelectors } from 'src/app/reducers';
import {
  addPinToCentre,
  getStoredMarkers,
  goToSelectedAddress,
  onGeocodingDragEnd,
  useGeocodePosition,
} from 'src/utils/geocoding';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL, DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';
import {
  MAP_TYPES,
  ZOOM_SELECTION_TOLERANCE_LOW,
  OPERATIONS,
  MONITOR_LOCATIONS,
  EVENT_NOISE_DB_LAYER,
} from 'src/constants';
// import { IGeocodeCandidateDetail } from 'src/app/props';

import {
  ITag,
  addTagToList,
  removeTagFromList,
  batchRemoveTagFromList,
  vectorLayerToPoint,
  fetchTagOperationData,
} from 'src/utils/mapTagHelpers';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';

import { fetchTileCursor } from 'src/@infringements/resolvers/summaryResolver';

// context
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { InfringementStateContext } from 'src/@infringements/providers/InfringementsStateProvider';

// Selectors
import { useTagDataContext } from 'src/app/reducers/tagDataReducer';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';
import { useGeometrySelectors } from 'src/app/reducers';
import { MapLegend } from 'src/components/MapLegend';
import { useScreenLoadStateContext } from 'src/app/providers/ScreenLoadStateContext';

export const InfringementsMap: FC<IMapProps> = ({ noiseMonitors = [], noiseData = [] }) => {
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        backToCenter: backToCenterLabel,
        ruler: ruler,
      },
    },
    abbreviations,
  } = languageSelectors.getLanguage();

  const labels = Object.assign(opsFields, abbreviations);

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // getting map style layers
  const layers = useMapLayer({ mapApis, mapBoxConfig, maptype: 'infringements' });
  // changing map tiles when dates change
  const { dateRangeMapping, datesArray } = useDatesDataForMap(
    mapApis,
    mapBoxConfig,
    layers,
    false,
    MAP_TYPES.INFRINGEMENTDETAILS
  );

  const dataSelectors = useDataSelectors();
  const {
    requiredData,
    infringementTypes,
    addedToSelection,
    removedFromSelection,
  } = dataSelectors.getRequiredDataForMap();

  const { isLoading } = dataSelectors.getDataInformation();

  const infRuleSelectors = useInfringementRulesSelectors();
  let extraGateIds: number[] = [];

  requiredData.map(infringement => {
    if (infringement.infringementType === 'Gate') {
      extraGateIds = extraGateIds.concat(infRuleSelectors.getGateIdsByRuleId(infringement.ruleId));
    }
  });

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  const { from: filterDateFrom, to: filterDateTo } = dateRangeStore.getDateFilters();

  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 4000);

  // add geometry shapes to map
  useGeometryRequiredByMap({
    mapApis,
    mapBoxConfig,
    infringementTypes,
    dateRange: {
      dateFilterFrom: dateTimeInQueryUTC(filterDateFrom, 'start'),
      dateFilterTo: dateTimeInQueryUTC(filterDateTo, 'end'),
    },
    mapStyle,
  });

  // getting coordinates for infringements
  const { infringementTracks, selectedInfringements } = useDataForMap(
    'inf-browser',
    mapApis,
    mapBoxConfig,
    viewport,
    setViewport,
    dateRangeMapping,
    requiredData,
    addedToSelection,
    removedFromSelection,
    extraGateIds,
    rerunHook
  );

  // Build filter for selected tracks and any active NMT's
  const geometrySelectors = useGeometrySelectors();
  const noiseMonitorLocations = geometrySelectors.getMonitorLocations();
  const { infringementFilter, clickFilter } = useMapTracksFilter({
    features: infringementTracks.operations,
    selectedInfringements,
    noiseMonitorLocations,
  });

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // handle hover and get hovered operation
  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: datesArray,
    tracksFilter: infringementFilter,
    radius: ZOOM_SELECTION_TOLERANCE_LOW,
    mapType: MAP_TYPES.INFRINGEMENTDETAILS,
    featureStateName: 'selected',
  });

  // set map selection for the hover object
  useMapHover(hoveredElement, mapApis);

  // Map selection
  const [, setShowSelected] = useState(false);
  const [selectedOperations, setSelectedOperations]: any = useState(null);

  const [tagList, setTagList] = useState<ITag[]>([]);

  // click handlers that provide selected operations
  const { handleClick, selectedOperation } = useMapClick({
    hoveredOperation: hoveredElement,
    setShowSelected,
    mapApis,
    datesArray,
    tracksFilter: clickFilter,
    selectedOperations,
    setSelectedOperations,
    mapType: MAP_TYPES.INFRINGEMENTDETAILS,
    clearDisplayedTags: () => setTagList([]),
  });

  useEffect(() => {
    addTagToList(selectedOperation, tagList, setTagList);
  }, [selectedOperation]);

  // set feature state for the hovered operation
  useMapSelection(selectedOperations, mapApis, 'infringement-select', selectedInfringements);

  // reset hover and select when selection changes
  useEffect(() => {
    setShowSelected(false);

    setSelectedOperations([]);
  }, [selectedInfringements.length]);

  const selectedMonitor: any[] = useStaticDbDisplay(mapApis, noiseMonitors, noiseData, false);

  const noisePopups: JSX.Element[] = [];
  if (selectedMonitor.length > 0) {
    selectedMonitor.map((monitor: any) => {
      const ruleList: IRuleData[] = requiredData.map(rule => infRuleSelectors.getRule(rule.ruleId));

      const ruleData = ruleList.find(rule =>
        (rule.thresholds || []).some(threshold => threshold.locationId === monitor.properties.id)
      );

      noisePopups.push(
        <NoisePopup
          key={`popup_${monitor.properties.id}`}
          zoomLevel={mapApis.getZoom()}
          monitorData={monitor}
          noiseData={noiseData}
          ruleData={ruleData}
        />
      );
    });
  }
  // Ruler Tool

  const units = configSelectors.getUnits();

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  useEffect(() => {
    // When table items are deselected, we need to remove any tags attached to tracks that no longer exist
    if (!infringementTracks.fetchingTracks) {
      const uuidsToRemove: string[] = [];
      const selectedInfringementIds = infringementTracks.selectedTracks.map(
        operation => operation.id
      );
      tagList.forEach(tag => {
        const tagExists = selectedInfringementIds.some(id => id === tag.data.id);
        if (!tagExists) {
          uuidsToRemove.push(tag.uuid);
        }
      });

      if (infringementTracks.operations) {
        const nearbyFlightIds = infringementTracks.operations.map(operation => operation.id);
        tagList.forEach(tag => {
          const tagExists = nearbyFlightIds.some(id => id === tag.data.id);
          if (!tagExists) {
            uuidsToRemove.push(tag.uuid);
          }
        });
      }
      batchRemoveTagFromList(uuidsToRemove, tagList, setTagList);
    }
  }, [infringementTracks]);
  const client = useApolloClient();
  const dispatcher: any = useContext(InfringementDispatchContext);
  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);
  const storedMarkers = getStoredMarkers();
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>(
    storedMarkers ? storedMarkers.main : { longitude: 0, latitude: 0 }
  );
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [drag, updateDragStatus] = useState<boolean>(false);
  const { removeHovered } = useMapHover(hoveredElement, mapApis, drag);

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  const { pageInfo } = dataSelectors.getDataInformation();

  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  const [mapApiStartCursor, setMapApiStartCursor] = useState<string>();
  const [tileLayers, setTileLayers] = useState<string[]>([]);

  const { areTilesLoaded, setAreTilesLoaded } = useHaveTilesLoaded({
    mapApis,
    isLoading,
    totalCount: dataSelectors.getTotalCount(),
    layerPrefixesToCheck: ['trackLayer_', 'track_'],
  });

  // Connect map load state to table load state
  const { screenLoadStates, setScreenLoadStates } = useScreenLoadStateContext();
  const { mapLoadState, tableLoadState } = screenLoadStates;

  useEffect(() => {
    if (areTilesLoaded) {
      // Hack till we can properly onReady map sources etc.
      setTimeout(() => {
        setScreenLoadStates({ type: 'SET_MAP_LOAD_STATE', payload: !areTilesLoaded });
      }, 2000);
    } else {
      setScreenLoadStates({ type: 'SET_MAP_LOAD_STATE', payload: !areTilesLoaded });
    }
  }, [areTilesLoaded]);

  useEffect(() => {
    if (isLoading === true) {
      setAreTilesLoaded(false);

      // Also wipe tags from map
      setTagList([]);
    }
  }, [isLoading, mapStyle]);

  useEffect(() => {
    const handleFetchingTileCursor = async () => {
      const queryParams = `
      startTime: "${dateTimeInQuery(filterDateFrom, 'start')}"
      endTime: "${dateTimeInQuery(filterDateTo, 'end')}"
      first:${100}
    `;
      const tileCursor = await fetchTileCursor(
        client,
        `${queryParams}  filter: {hasInfringements: true}`
      );
      setMapApiStartCursor(tileCursor);
    };

    if (pageInfo && FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
      if (pageInfo.startCursor) {
        handleFetchingTileCursor();
      }
    }
  }, [pageInfo]);

  useEffect(() => {
    const handleTileLoading = async () => {
      const trackTiles = await fetchTrackTiles({ startCursor: mapApiStartCursor });
      setTileLayers(trackTiles);
    };
    if (mapApiStartCursor) {
      handleTileLoading();
    }
  }, [mapApiStartCursor, mapStyle]);

  const updateLayerFunction = useCallback(() => {
    if (tileLayers.length) {
      addCustomTileSource({
        mapApis,
        trackPaths: tileLayers,
        trackVisibility: true,
        paintStyle: mapboxStyleInfringementPaint(),
      });
    }
  }, [mapApis, tileLayers]);

  useRerunFunctionOnMapBackgroundChange({ mapApis, mapStyle, reloadFunction: updateLayerFunction });
  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  const tagDataState = useTagDataContext(InfringementStateContext).getTagData();

  useEffect(() => {
    tagList.forEach(({ data }) => {
      const { id } = data;
      if (!(id in tagDataState)) {
        fetchTagOperationData(id, dispatcher, client);
      }
    });
  }, [tagList]);

  const HoveredTag = ({ element }) => {
    const hoveredOperationId = hoveredElement.id || hoveredElement.properties.id;
    if (!tagDataState[hoveredOperationId]) {
      fetchTagOperationData(hoveredOperationId, dispatcher, client);
    }
    const { metadata }: { metadata: { tagType: string } } = element.layer;

    if (metadata.tagType === OPERATIONS) {
      return (
        <AMSLPopup
          labels={labels}
          pointData={
            tagDataState[hoveredOperationId] && tagDataState[hoveredOperationId].data
              ? vectorLayerToPoint({
                  operation: tagDataState[hoveredOperationId].data,
                  clickedElement: element,
                })
              : element
          }
          draggable={false}
          isLoading={
            tagDataState[hoveredOperationId] ? tagDataState[hoveredOperationId].isLoading : true
          }
        />
      );
    } else if (metadata.tagType === MONITOR_LOCATIONS) {
      const { properties, latitude, longitude } = element;
      return (
        <NMTPopup lat={latitude} lon={longitude} draggable={false}>
          <>
            <p className="amsl-popup_title">{properties.name}</p>
            <p className="amsl-popup_value">{properties.description}</p>
          </>
        </NMTPopup>
      );
    }
  };

  return (
    <div
      className={cx({
        map_wrapper: !isFullScreen,
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        <StyledMap
          isLoading={mapLoadState || tableLoadState}
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          {...mapProps}
          ref={mapRef}
          onMouseMove={undefined}
          onClick={handleClick}
          onHover={debounce(handleHover, 200)}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          height={mapHeight}>
          {isLocationTagOpen && (
            <LocationPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress || place}
              elevation={elevation}
              languageData={{ latLabel, lngLabel, amslLabel }}
              mapApis={mapApis}
              showFilterButton={false}
              onClose={() => {
                updateLocationTagOpen(!isLocationTagOpen);
              }}
            />
          )}
          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable
            mapApis={mapApis}
            addRemoveCircles={addRemoveCircles}
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              removeHovered();
              updateDragStatus(true);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]: number[]) =>
              onGeocodingDragEnd({
                longitude,
                latitude,
                updateDragStatus,
                updateGeocoding,
                updateLocationAddress,
                updateLocationTagOpen,
                addRemoveCircles,
              })
            }
            onMouseEnter={() => {
              removeHovered();
              updateDragStatus(true);
            }}
            onMouseLeave={() => {
              updateDragStatus(false);
            }}
          />
          {enableMapControls && (
            <MapControl
              isPinAdded={latitude && longitude ? true : false}
              addPinToCentre={() =>
                addPinToCentre({
                  updateLocationAddress,
                  geocoding,
                  viewport,
                  updateGeocoding,
                  addRemoveCircles,
                  updateLocationTagOpen,
                })
              }
              translationData={{
                home: backToCenterLabel,
                mapSettings: mapSettingsTitle,
                ruler,
              }}
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSearch: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              addressSearch={AddressSearch}
              closeSearch={closeSearch}
              resetView={resetView}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    onUpdate={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          {noisePopups}

          {tagList.map(({ data, uuid }) => {
            const matchingOperation = tagDataState[data.id];
            if (!matchingOperation) {
              return null;
            }
            const { metadata } = data.layer as { metadata: { tagType: string } };

            if (metadata.tagType === OPERATIONS) {
              return (
                <AMSLPopup
                  labels={labels}
                  pointData={
                    matchingOperation.data
                      ? vectorLayerToPoint({
                          operation: matchingOperation.data,
                          clickedElement: data,
                        })
                      : selectedOperation
                  }
                  draggable
                  isLoading={matchingOperation.isLoading}
                  onClose={() => removeTagFromList(uuid, tagList, setTagList)}
                  key={uuid}
                />
              );
            } else if (metadata.tagType === MONITOR_LOCATIONS) {
              const { properties, latitude, longitude } = data;
              return (
                <NMTPopup
                  lat={latitude}
                  lon={longitude}
                  draggable
                  key={uuid}
                  onClose={() => removeTagFromList(uuid, tagList, setTagList)}>
                  <>
                    <p className="amsl-popup_title">{properties.name}</p>
                    <p className="amsl-popup_value">{properties.description}</p>
                  </>
                </NMTPopup>
              );
            }
          })}
          {!drag && hoveredElement && hoveredElement.latitude && hoveredElement.longitude && (
            <HoveredTag element={hoveredElement} />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(filterDateFrom, 'start'),
              dateFilterTo: dateTimeInQueryUTC(filterDateTo, 'end'),
            }}
            afterRender={() => {
              const mapStyles = mapApis.getStyle();
              if (
                Object.keys(mapStyles).length &&
                mapStyles.layers.some(({ id }) => id === EVENT_NOISE_DB_LAYER)
              ) {
                mapApis.moveLayer(EVENT_NOISE_DB_LAYER);
              }
            }}
          />
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
          <MapLegend layersDisplayed={layersDisplayed} />
        </StyledMap>
      </div>
    </div>
  );
};
