import React from 'react';
import { PlaybackPanelWrapper, PlaybackPanel } from './style';
import { CalendarButton, ControlButtons, PlaybackButton, Slider } from './components';
import { isMobileOnly } from 'react-device-detect';

export const PlaybackControl = () => (
  <PlaybackPanelWrapper>
    <PlaybackPanel isMobile={isMobileOnly}>
      <PlaybackButton />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: '0 8px',
        }}>
        <Slider />
        <ControlButtons />
      </div>
      <CalendarButton />
    </PlaybackPanel>
  </PlaybackPanelWrapper>
);
