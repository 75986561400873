import { LineLayerSpecification } from 'mapbox-gl';
import { RealtimeLayerIds } from 'src/@realtime/constants';

const LINE_WIDTH = 2;

// Base layout and type, fully defined
const baseLineLayer: Pick<LineLayerSpecification, 'type' | 'layout'> = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
};

// Specific tail styles extending the base properties
export const selectedTailStyle: LineLayerSpecification = {
  ...baseLineLayer,
  id: RealtimeLayerIds.defaultTail,
  source: RealtimeLayerIds.defaultTail,
  paint: {
    'line-color': ['get', 'operationColor'],
    'line-width': LINE_WIDTH,
  },
};

export const selectedHiddenTailStyle: LineLayerSpecification = {
  ...baseLineLayer,
  id: RealtimeLayerIds.selectedHiddenTail,
  source: RealtimeLayerIds.selectedTail,
  paint: {
    'line-color': 'white',
    'line-width': LINE_WIDTH * 3,
    'line-opacity': 0,
  },
};

export const defaultTailStyle: LineLayerSpecification = {
  ...baseLineLayer,
  id: RealtimeLayerIds.selectedTail,
  source: RealtimeLayerIds.selectedTail,
  paint: {
    'line-color': ['get', 'operationColor'],
    'line-width': LINE_WIDTH,
    'line-dasharray': [1, 2],
  },
};
